// @flow

/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */

import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';

import { CAN_USE_DOM } from 'common/constants';
import { ROOT_ROUTE, SEO, MAKO_ANALYTICS_PAGE_IDS } from 'client/constants';
import { analytics } from 'common/utils';
import { Header, MakoAnalytics } from 'client/components/common';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';

import faviconImg from 'assets/favicon.png';

import SecondScreen from '../../common/Sreens/SecondScreen';

import * as css from './Channels.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	otherShows: Array<ModifiedShowItem>,
	checkedShows: Array<ModifiedShowItem>,
	selectedChannels: Array<string>,
	allChannels: Array<string>,
	history: RouterHistory,
	location: RouterLocation,
	onInit: () => void,
	manageSelectedChannel: ManageSelectedChannelMethod,
	setAllRecommendations: (Array<Array<any>>) => void,
|};
class Shows extends React.PureComponent<Props> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	constructor(props: Props) {
		super(props);

		if (props.checkedShows.length === 0) {
			props.history.push(ROOT_ROUTE);
		}

		props.onInit();
	}

	componentDidMount() {
		const { location } = this.props;
		const url = location.pathname.split('/')[1];

		if (CAN_USE_DOM) {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
			analytics.pageview(url);
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const { location } = this.props;
		const title = SEO.general.seoTitle;
		// eslint-disable-next-line max-len
		const description = SEO.general.seoDescriptionChannels;
		const ogTitle = SEO.general.shareTitle;
		const ogDescription = SEO.general.shareDescription;
		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = 'https://myseries.mako.co.il/assets/client/share.png';

		return (
			<Helmet
				title={title}
				link={[
					{ rel: 'icon', type: 'image/png', href: faviconImg },
					{ rel: 'canonical', href: location.pathname },
				]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
					{ property: 'og:url,', content: '' },
					{ property: 'og:type', content: 'website' },
				]}
			/>
		);
	};

	render() {
		const {
			className,
			transitionState,
			allChannels,
			otherShows,
			checkedShows,
			selectedChannels,
			history,
			setAllRecommendations,
			manageSelectedChannel,
		} = this.props;
		return (
			<div className={classNames(css.channels, css[transitionState], className)}>
				{this.renderHelmet()}
				<Header isHideSocialls history={history} backRoute={ROOT_ROUTE} />
				<SecondScreen
					allChannels={allChannels}
					otherShows={otherShows}
					checkedShows={checkedShows}
					selectedChannels={selectedChannels}
					history={history}
					setAllRecommendations={setAllRecommendations}
					manageSelectedChannel={manageSelectedChannel}
				/>
				<MakoAnalytics pageId={MAKO_ANALYTICS_PAGE_IDS.PROVIDERS} />
			</div>
		);
	}
}

export default withTransition(Shows);
