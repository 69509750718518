/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import yellowBgDesk from 'assets/client/images/yellow-bg-desktop.svg';
import yellowBgMob from 'assets/client/images/yellow-bg-mobile.svg';
import beeLogo from 'assets/client/images/logo-tvbee-black.svg';
import beeImg from 'assets/client/images/bee_results.svg';
import { Responsive } from '../../Responsive';
import Rate from './Rate';

import * as css from './ResultScreen.scss';

type Props = {|
	className?: string,
	shareRecommendations: Array<any>,
	hidePopup: Function,
|};

type State = {
	firstClickOnClose: boolean,
};

class MobilePopup extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		firstClickOnClose: false,
	};

	componentDidMount() {
		// this.setState({
		// 	recommendations,
		// });
	}

	componentDidUpdate(prevProps: Props) {}
	clickOnClose = () => {
		const { firstClickOnClose } = this.state;
		const { hidePopup } = this.props;
		if (firstClickOnClose) {
			hidePopup();
		} else {
			this.setState({
				firstClickOnClose: true,
			});
		}
	};
	render() {
		const { className, shareRecommendations } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const topSectionBgUrl = isMobile ? yellowBgMob : yellowBgDesk;
		const { firstClickOnClose } = this.state;

		return (
			<div className={classNames(css.mobilePopup, className)}>
				<div className={classNames(css.popupTopBar, firstClickOnClose && css.hide)}>
					<div className={classNames(css.text, firstClickOnClose && css.hide)}>צלמו מסך ושתפו את העולם</div>
					<div className={css.close} onClick={this.clickOnClose}></div>
				</div>
				<div className={css.popupTopSection} style={{ backgroundImage: `url("${topSectionBgUrl}")` }}>
					<div className={css.popupTopSectionWrapper}>
						<div className={css.beeLogo}>
							<img src={beeLogo} alt="logo" />
						</div>
						<h1 className={css.headTitle}>
							<span>3 סדרות שאתם </span>
							<span>חייבים לראות</span>
						</h1>

						{!isMobile && (
							<div className={css.beeAnimation}>
								<img src={beeImg} alt="bee" />
							</div>
						)}
					</div>
				</div>
				<div className={css.popupRecommendations}>
					{shareRecommendations.map(item => {
						const genreString = item.genre.join(', ');

						return (
							<div className={classNames(css.popupRecommendation, className)} key={item.id}>
								{item && (
									<div className={classNames(css.recommendationItem)}>
										<div
											className={classNames(css.recommendationItemImage)}
											style={{ backgroundImage: `url(${item.imgix})` }}
										>
											<img src={item.imgix} alt={item.nameHebrew} title={item.nameHebrew} />
										</div>
										<div className={classNames(css.recommendationItemInfo)}>
											<div className={css.name}>{item.nameHebrew}</div>
											<div className={css.genre}>{genreString}</div>
											<div className={css.channels}>
												{_.map(item.channels, ch => (
													<span className={css.channelItem} key={ch}>
														{ch}
													</span>
												))}
											</div>
										</div>
										<Rate item={item} />
									</div>
								)}
							</div>
						);
					})}
				</div>
			</div>
		);
	}
}

export default MobilePopup;
