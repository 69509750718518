/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import Lottie from 'react-lottie-player';

import yellowBgDesk from 'assets/client/images/yellow-bg-desktop.svg';
import yellowBgMob from 'assets/client/images/yellow-bg-mobile.svg';
import lottieJson from '../../JsonAnim/bee_anim_first_and_second_screen.json';
import { Responsive } from '../../Responsive';

import * as css from './FirstScreen.scss';

const TopSection = () => {
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const topSectionBgUrl = isMobile ? yellowBgMob : yellowBgDesk;
	return (
		<div className={css.topSection} style={{ backgroundImage: `url("${topSectionBgUrl}")` }} id="top-section">
			<div className={css.topSectionWrapper}>
				<h1 className={css.headTitle}>
					<span>מה רואים</span>
					<span>היום?</span>
				</h1>
				<div className={css.text}>
					<p>
						שוב הגיע הערב ונתקעתם בלי סדרה?
						<br /> אנחנו כאן כדי לעזור! סמנו אילו סדרות אהבתם, ואנחנו נמליץ לכם על ההתמכרות הבאה שלכם
					</p>
				</div>
				<div className={css.beeAnimation}>
					<Lottie loop animationData={lottieJson} play style={{ width: 1200, height: 250 }} />
				</div>
			</div>
		</div>
	);
};

export default TopSection;
