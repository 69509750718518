// @flow

/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */

import * as React from 'react';
import classNames from 'classnames';

import { RESULT_RECOMMENDATIONS_ROUTE } from 'client/constants';
import { gaFour } from 'common/utils';

import recommendationsResult from './recommendationsResult';

import RabbiCredit from '../../RabbiCredit';
import BottomLink from '../../BottomLink';
import TopSection from './TopSection';
import BottomBar from './BottomBar';
import ChannelImage from './ChannelImage';

import { sortedByNewAmazingLogic } from './helpers';

import * as css from './SecondScreen.scss';

type Props = {|
	className?: string,
	allChannels: Array<string>,
	otherShows: Array<ModifiedShowItem>,
	selectedChannels: Array<any>,
	checkedShows: Array<ModifiedShowItem>,
	history: RouterHistory,
	manageSelectedChannel: ManageSelectedChannelMethod,
	setAllRecommendations: (Array<Array<any>>) => void,
|};

type State = {
	selectedChannels: Array<string>,
};

class SecondScreen extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	// toggleChannel2 = (e: SyntheticEvent<HTMLDivElement>) => {
	// 	const { setSlectedChannels } = this.props;
	// 	const { selectedChannels } = this.state;
	// 	const { name } = e.currentTarget.dataset;

	// 	const selectedChannel = selectedChannels.find(item => {
	// 		return item === name;
	// 	});

	// 	if (selectedChannel) {
	// 		setSlectedChannels(selectedChannels.filter(i => i !== selectedChannel));
	// 		this.setState(prevState => ({
	// 			selectedChannels: prevState.selectedChannels.filter(i => i !== selectedChannel),
	// 		}));
	// 	} else {
	// 		setSlectedChannels([...selectedChannels, name]);
	// 		this.setState(prevState => ({
	// 			selectedChannels: [...prevState.selectedChannels, name],
	// 		}));
	// 	}
	// };

	toggleChannel = (e: SyntheticEvent<HTMLDivElement>) => {
		const { manageSelectedChannel, selectedChannels } = this.props;
		const { name } = e.currentTarget.dataset;
		const isChannelAlreadyAdded = selectedChannels.indexOf(name) < 0;

		manageSelectedChannel(name, isChannelAlreadyAdded ? 'add' : 'remove');
	};

	getRecommendations = (includeCheckingChannels: boolean = false) => {
		const { checkedShows, selectedChannels, otherShows } = this.props;
		const allRecommendations = [];

		// console.info('checkedShows', checkedShows);

		checkedShows.forEach((checkedShow, checkedShowIndex) => {
			const recommendations: any = [];
			const checkedShowGenre = checkedShow.genre;

			// update each show, added genre counter and channels counter for future sorting
			otherShows.forEach(show => {
				const genres = show.genre;
				const { channels } = show;

				let isSuitable = true;
				let showGenreCounter = 0;
				let showChannelsCounter = 0;

				if (show.genre.length > checkedShowGenre.length) return;

				genres.forEach((genre, index) => {
					const generCheck = checkedShowGenre.find(checkedShowGenreItem => checkedShowGenreItem === genre);

					if (!generCheck) {
						isSuitable = false;
						return;
					}
					if (generCheck) {
						showGenreCounter += 1;
					}
				});

				if (includeCheckingChannels) {
					channels.forEach((channel, index) => {
						const channelCheck = selectedChannels.find(
							selectedChannelsItem => selectedChannelsItem === channel
						);

						if (!channelCheck) {
							return;
						}

						if (channelCheck) {
							showChannelsCounter += 1;
						}
					});
				}

				if (isSuitable) {
					if (showGenreCounter === checkedShowGenre.length) {
						// 10 it means checkedShow and show has absolutely identical geners
						const recomShow = {
							...show,
							genreCount: 10,
							channelsCount: includeCheckingChannels ? showChannelsCounter : null,
						};
						recommendations.push(recomShow);
					} else {
						const recomShow = {
							...show,
							genreCount: showGenreCounter,
							channelsCount: includeCheckingChannels ? showChannelsCounter : null,
						};
						recommendations.push(recomShow);
					}
				}
			});

			// sort recommendations by genre count(tags), by channels, by year (if need) and by rate (if need)
			if (includeCheckingChannels) {
				recommendations.sort((a, b) => {
					if (
						a.channelsCount &&
						b.channelsCount &&
						a.channelsCount === b.channelsCount &&
						a.genreCount === b.genreCount
					) {
						const lastShowA = Number(a.LastShow.split('/')[2]);
						const lastShowB = Number(b.LastShow.split('/')[2]);
						if (lastShowB === lastShowA) {
							return Number(b.editorsRate) - Number(a.editorsRate);
						}
						return lastShowB - lastShowA;
					}

					if (
						a.channelsCount &&
						b.channelsCount &&
						a.channelsCount === b.channelsCount &&
						a.genreCount !== b.genreCount
					) {
						return b.genreCount - a.genreCount;
					}

					return b.channelsCount - a.channelsCount;
				});
			} else {
				recommendations.sort((a, b) => {
					if (a.genreCount === b.genreCount) {
						const lastShowA = Number(a.LastShow.split('/')[2]);
						const lastShowB = Number(b.LastShow.split('/')[2]);
						if (lastShowB === lastShowA) {
							return Number(b.editorsRate) - Number(a.editorsRate);
						}
						return lastShowB - lastShowA;
					}

					return b.genreCount - a.genreCount;
				});
			}

			const spliced = recommendations;
			allRecommendations.push(spliced);
		});

		const resultRecommendations = recommendationsResult(allRecommendations, true);

		return sortedByNewAmazingLogic(resultRecommendations);
	};

	clickOnSkip = () => {
		const { history, setAllRecommendations } = this.props;

		const targetResultRecommendations = this.getRecommendations(false /* includeCheckingChannels */);

		localStorage.setItem('recommendations', JSON.stringify(targetResultRecommendations));
		setAllRecommendations(targetResultRecommendations);
		gaFour.gtag.event('Recommend me', { CUSTOM_PARAMETER: 'no_provider' });
		history.push(RESULT_RECOMMENDATIONS_ROUTE);
	};

	clickOnToRecommendations = () => {
		const { history, setAllRecommendations, selectedChannels } = this.props;

		if (selectedChannels.length === 0) return;

		const targetResultRecommendations = this.getRecommendations(true /* includeCheckingChannels */);

		gaFour.gtag.event('Recommend me', { CUSTOM_PARAMETER: selectedChannels.join(',') });
		localStorage.setItem('recommendations', JSON.stringify(targetResultRecommendations));
		setAllRecommendations(targetResultRecommendations);

		history.push(RESULT_RECOMMENDATIONS_ROUTE);
	};

	renderChannel = (channel: string) => {
		const { selectedChannels } = this.props;
		const isSelected = selectedChannels.find(i => {
			return i === channel;
		});

		if (isSelected) {
			return (
				<div
					key={channel}
					data-name={channel}
					className={classNames(css.channelBox, css.selected)}
					onClick={this.toggleChannel}
				>
					<ChannelImage channel={channel} />
				</div>
			);
		}
		return (
			<div key={channel} data-name={channel} className={classNames(css.channelBox)} onClick={this.toggleChannel}>
				<ChannelImage channel={channel} />
			</div>
		);
	};

	render() {
		const { className, allChannels, selectedChannels, history } = this.props;
		const isDisableToRecommendations = selectedChannels.length === 0;

		const desktopFirstRowChannels = allChannels.slice(0, 5);
		const desktopSecondRowChannels = allChannels.slice(5, 9);
		const desktopThirdRowChannels = allChannels.slice(9, 14);
		return (
			<div className={classNames(css.secondScreen, className)}>
				<TopSection history={history} />
				<div className={css.channels}>
					<div className={css.channelsRow}>
						{desktopFirstRowChannels.map(channel => {
							return this.renderChannel(channel);
						})}
					</div>
					<div className={css.channelsRow}>
						{desktopSecondRowChannels.map(channel => {
							return this.renderChannel(channel);
						})}
					</div>
					<div className={css.channelsRow}>
						{desktopThirdRowChannels.map(channel => {
							return this.renderChannel(channel);
						})}
					</div>
				</div>
				<div className={css.channelsMobile}>
					{allChannels.map(channel => {
						return this.renderChannel(channel);
					})}
				</div>
				<div className={css.skipMobile}>
					<div className={css.skipMobileWrapper} onClick={this.clickOnSkip}>
						אני כבר אמצא איפה לראות, תמליצו לי!
					</div>
				</div>
				<RabbiCredit />
				<BottomLink />
				<div className={css.honeycombs}></div>
				<BottomBar
					isDisableBtn={isDisableToRecommendations}
					clickOnSkip={this.clickOnSkip}
					clickOnToRecomendations={this.clickOnToRecommendations}
				/>
			</div>
		);
	}
}

export default SecondScreen;
