// @flow
import produce from 'immer';

import * as actions from 'client/constants/actions';

const initialState: ItemsClientStoreType = {
	isBusy: false,
	items: [],
};

type Action =
	| { type: typeof actions.GET_STARRED_ITEMS.START, payload: any }
	| { type: typeof actions.GET_STARRED_ITEMS.SUCCESS, payload: any }
	| { type: typeof actions.GET_STARRED_ITEMS.FAIL, payload: any };

export default function settingsReducer(state: ItemsClientStoreType = initialState, action: Action) {
	return produce<any>(state, (draft: ItemsClientStoreType) => {
		switch (action.type) {
			case actions.GET_STARRED_ITEMS.START:
				draft.isBusy = true;
				break;

			case actions.GET_STARRED_ITEMS.SUCCESS:
				draft.items = action.payload;
				draft.isBusy = false;
				break;

			case actions.GET_STARRED_ITEMS.FAIL:
				draft.isBusy = false;
				break;

			default:
				break;
		}
	});
}
