/* eslint-disable no-unused-expressions */
// @flow

import * as React from 'react';
import classNames from 'classnames';
import { Responsive } from 'client/components/common/Responsive';
import tvBeeSrc from 'assets/client/images/logo-tvbee-yellow.svg';
import macoSrc from 'assets/client/images/logo-mako.svg';
import { gaFour } from 'common/utils';
import { Icon } from 'client/components/common/Icon';
import { ROOT_ROUTE } from 'client/constants';
import Socials from '../Socials';
import * as css from './Header.scss';

type Props = {
	className?: string,
	withBackBtn?: boolean,
	isHideSocialls?: boolean,
	backRoute?: string,
	history?: RouterHistory,
};

const Header = ({ className, withBackBtn, isHideSocialls, backRoute, history }: Props) => {
	const tvBeeLink = 'https://www.tvbee.co.il/';
	const makoLink = 'https://www.mako.co.il/';
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const isHideOnMobileSocialls = isMobile && isHideSocialls;
	const isHide = withBackBtn && isHideSocialls;
	let hidesSocialls;
	if (isHideOnMobileSocialls || isHide) {
		hidesSocialls = true;
	} else {
		hidesSocialls = false;
	}

	const clickOnBackBtn = () => {
		history?.push(ROOT_ROUTE);
		gaFour.gtag.event('Back to search', { CUSTOM_PARAMETER: 'results page' });
	};

	const clickOnTvBeeLogo = () => {
		gaFour.gtag.event('header click', { CUSTOM_PARAMETER: 'tvbee logo' });
	};

	const clickOnMakoLogo = () => {
		gaFour.gtag.event('header click', { CUSTOM_PARAMETER: 'mako logo' });
	};

	return (
		<div className={classNames(css.header, className)} id="header">
			<div className={css.headerWrapper}>
				<div className={css.rightSide}>
					<a
						href={tvBeeLink}
						onClick={clickOnTvBeeLogo}
						target="_blank"
						rel="noreferrer"
						className={css.tvBeeLogo}
					>
						<img
							src={tvBeeSrc}
							alt="מה רואים היום מבית MAKO"
							title="מה רואים היום מבית MAKO"
							value="סדרות מומלצות מבית MAKO"
						/>
					</a>
				</div>
				<div className={css.leftSide}>
					<div className={css.logo}>
						<a href={makoLink} target="_blank" rel="noreferrer" onClick={clickOnMakoLogo}>
							<img src={macoSrc} alt="MAKO" title="MAKO" value="MAKO" />
						</a>
					</div>
					{!hidesSocialls && <Socials minified={isMobile} />}
					{withBackBtn && (
						<div className={css.backBtnWrapper} onClick={clickOnBackBtn}>
							בחזרה לחיפוש
							<div className={css.backBtn}>
								<Icon type="arrow-left" />
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

Header.defaultProps = {
	className: '',
	withBackBtn: false,
	isHideSocialls: false,
	backRoute: '',
	history: {},
};

export { Header };
