export default function recommendations(arr) {
	const incomeArray = arr;

	// console.info('array____', arr);
	const result = [];
	// console.info('incomeArray', incomeArray);
	incomeArray.forEach((recommendationsArr, recommendationsArrIndex) => {
		const resultArray = [];

		recommendationsArr.forEach((recomItem, itemIndex) => {
			const itemId = recomItem.id;
			const arrBooleanValues = [];
			let coincidencesBefore = 0;
			let coincidencesAfter = 0;
			incomeArray.forEach(item => {
				if (item[itemIndex]) {
					if (item[itemIndex].id === itemId) {
						arrBooleanValues.push(true);
					} else {
						arrBooleanValues.push(false);
					}
				} else {
					arrBooleanValues.push(false);
				}
			});

			arrBooleanValues.forEach((booleanItem, index) => {
				if (index > recommendationsArrIndex && booleanItem) {
					coincidencesAfter += 1;
				}

				if (index < recommendationsArrIndex && booleanItem) {
					coincidencesBefore += 1;
				}
			});

			// if (resultArray.length === 4) return;

			// check if item has not duplications in the same level
			if (coincidencesBefore === 0 && coincidencesAfter === 0) {
				resultArray.push(recomItem);
			}

			// check if item is the first in array and has duplications in tha same level, but after the item
			if (recommendationsArrIndex === 0 && coincidencesAfter !== 0) {
				if (itemIndex === 0) {
					resultArray.push(recommendationsArr[0]);
				}
				if (itemIndex !== 0 && (itemIndex + coincidencesAfter + 1) % (coincidencesAfter + 1) === 0) {
					resultArray.push(recomItem);
				}
			}

			// check if item has duplications in tha same level, but only before the item and only on the same level
			if (recommendationsArrIndex !== 0 && coincidencesBefore !== 0 && coincidencesAfter === 0) {
				if ((itemIndex + coincidencesBefore) % (coincidencesBefore + 1) === 0) {
					resultArray.push(recomItem);
				}
			}
			// check if item has duplications in tha same level, but only after the item and only on the same level
			if (recommendationsArrIndex !== 0 && coincidencesBefore === 0 && coincidencesAfter !== 0) {
				if (itemIndex === 0) {
					resultArray.push(recomItem);
				}
				if (itemIndex !== 0 && (itemIndex + coincidencesAfter + 1) % (coincidencesAfter + 1) === 0) {
					resultArray.push(recomItem);
				}
			}
		});
		result.push(resultArray);
	});

	// console.info('result________', withoutDuplications(result));
	return withoutDuplications(result);
}

function withoutDuplications(arr) {
	const resultWithoutDuplications = [];

	arr.forEach((recomItemsArr, index) => {
		const resArr = [];

		if (recomItemsArr.length === 0) return;
		// console.info('recomItemsArr', recomItemsArr);
		if (index === 0) {
			recomItemsArr.forEach(i => {
				if (resArr.length === 4) return;
				resArr.push(i);
			});
		}
		if (index === 1) {
			recomItemsArr.forEach(i => {
				const isСoincidenceFirst =
					resultWithoutDuplications[0] &&
					resultWithoutDuplications[0].length > 0 &&
					resultWithoutDuplications[0].find(el => {
						return el.id === i.id;
					});
				if (isСoincidenceFirst) return;
				resArr.push(i);
			});
		}

		if (index === 2) {
			recomItemsArr.forEach(i => {
				const isСoincidenceFirst =
					resultWithoutDuplications[0] &&
					resultWithoutDuplications[0].length > 0 &&
					resultWithoutDuplications[0].find(el => {
						return el.id === i.id;
					});
				const isСoincidenceSecond =
					resultWithoutDuplications[1] &&
					resultWithoutDuplications[1].length > 0 &&
					resultWithoutDuplications[1].find(el => {
						return el.id === i.id;
					});

				if (isСoincidenceFirst || isСoincidenceSecond) return;
				resArr.push(i);
			});
		}

		if (index === 3) {
			recomItemsArr.forEach(i => {
				const isСoincidenceFirst =
					resultWithoutDuplications[0] &&
					resultWithoutDuplications[0].length > 0 &&
					resultWithoutDuplications[0].find(el => {
						return el.id === i.id;
					});
				const isСoincidenceSecond =
					resultWithoutDuplications[1] &&
					resultWithoutDuplications[1].length > 0 &&
					resultWithoutDuplications[1].find(el => {
						return el.id === i.id;
					});
				const isСoincidenceThird =
					resultWithoutDuplications[2] &&
					resultWithoutDuplications[2].length > 0 &&
					resultWithoutDuplications[2].find(el => {
						return el.id === i.id;
					});

				if (isСoincidenceFirst || isСoincidenceSecond || isСoincidenceThird) return;
				resArr.push(i);
			});
		}

		if (index === 4) {
			recomItemsArr.forEach(i => {
				const isСoincidenceFirst =
					resultWithoutDuplications[0] &&
					resultWithoutDuplications[0].length > 0 &&
					resultWithoutDuplications[0].find(el => {
						return el.id === i.id;
					});
				const isСoincidenceSecond =
					resultWithoutDuplications[1] &&
					resultWithoutDuplications[1].length > 0 &&
					resultWithoutDuplications[1].find(el => {
						return el.id === i.id;
					});
				const isСoincidenceThird =
					resultWithoutDuplications[2] &&
					resultWithoutDuplications[2].length > 0 &&
					resultWithoutDuplications[2].find(el => {
						return el.id === i.id;
					});
				const isСoincidenceFourth =
					resultWithoutDuplications[3] &&
					resultWithoutDuplications[3].length > 0 &&
					resultWithoutDuplications[3].find(el => {
						return el.id === i.id;
					});

				if (isСoincidenceFirst || isСoincidenceSecond || isСoincidenceThird || isСoincidenceFourth) return;
				resArr.push(i);
			});
		}
		if (resArr.length > 0) {
			resultWithoutDuplications.push(resArr);
		}
	});

	// console.info('===resultWithoutDuplications===', resultWithoutDuplications);

	resultWithoutDuplications.sort((a, b) => {
		if (
			a[0].channelsCount !== undefined &&
			b[0].channelsCount !== undefined &&
			a[0].channelsCount === b[0].channelsCount
		) {
			const lastShowA = Number(a[0].LastShow.split('/')[2]);
			const lastShowB = Number(b[0].LastShow.split('/')[2]);
			if (lastShowB === lastShowA) {
				return Number(b[0].editorsRate) - Number(a[0].editorsRate);
			}
			return lastShowB - lastShowA;
		}

		if (a[0].channelsCount && b[0].channelsCount && a[0].channelsCount !== b[0].channelsCount) {
			return b[0].channelsCount - a[0].channelsCount;
		}

		if (!a[0].channelsCount && !b[0].channelsCount) {
			const lastShowA = Number(a[0].LastShow.split('/')[2]);
			const lastShowB = Number(b[0].LastShow.split('/')[2]);
			if (lastShowB === lastShowA) {
				return Number(b[0].editorsRate) - Number(a[0].editorsRate);
			}
			return lastShowB - lastShowA;
		}

		return b[0].channelsCount - a[0].channelsCount;
	});

	if (resultWithoutDuplications.length < 3) {
		const arr1Length = resultWithoutDuplications[0].length;
		const arr2Length = resultWithoutDuplications[1].length;
		const resultArr = [];
		if (arr1Length > arr2Length) {
			const devider = Math.floor(arr1Length / 2);
			const firstEl = resultWithoutDuplications[0].slice(0, devider);
			const secondEl = resultWithoutDuplications[0].slice(devider, arr1Length);
			const thirdEl = resultWithoutDuplications[1];
			resultArr.push(firstEl);
			resultArr.push(secondEl);
			resultArr.push(thirdEl);
		} else {
			const devider = Math.floor(arr2Length / 2);
			const firstEl = resultWithoutDuplications[0];
			const secondEl = resultWithoutDuplications[1].slice(0, devider);
			const thirdEl = resultWithoutDuplications[1].slice(devider, arr2Length);
			resultArr.push(firstEl);
			resultArr.push(secondEl);
			resultArr.push(thirdEl);
		}
		return resultArr;
	}
	// console.info('resultWithoutDuplications====', resultWithoutDuplications);
	return resultWithoutDuplications;
}
