// @flow
export const GET_ITEMS = {
	START: 'GET_ITEMS.START',
	SUCCESS: 'GET_ITEMS.SUCCESS',
	FAIL: 'GET_ITEMS.FAIL',
};

export const GET_STARRED_ITEMS = {
	START: 'GET_STARRED_ITEMS.START',
	SUCCESS: 'GET_STARRED_ITEMS.SUCCESS',
	FAIL: 'GET_STARRED_ITEMS.FAIL',
};
