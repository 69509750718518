/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { Helmet } from 'react-helmet-async';

import { Header, MakoAnalytics } from 'client/components/common';
import { analytics } from 'common/utils';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { CAN_USE_DOM, APP_CONFIG } from 'common/constants';
import { SEO, MAKO_ANALYTICS_PAGE_IDS } from 'client/constants';

import faviconImg from 'assets/favicon.png';

import FirstScreen from '../../common/Sreens/FirstScreen';

import * as css from './Shows.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	starredShows: Array<ModifiedShowItem>,
	onInit: () => void,
	setInfoPopUp: (limitType: 'min' | 'max') => void,
	manageCheckedShow: ManageCheckedShowMethod,
	checkedShows: Array<ModifiedShowItem>,
	history: RouterHistory,
	location: RouterLocation,
|};

type State = {};
class Shows extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	constructor(props) {
		super(props);

		props.onInit();
	}

	componentDidMount() {
		if (CAN_USE_DOM) {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
			if (this.props.location.search) {
				analytics.pageview(`HP${this.props.location.search}`);
			} else {
				analytics.pageview('HP');
			}
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const { location, starredShows } = this.props;
		const title = SEO.general.seoTitle;
		// eslint-disable-next-line max-len
		const description = SEO.general.seoDescription;
		const ogTitle = SEO.general.shareTitle;
		const ogDescription = SEO.general.shareDescription;
		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = 'https://myseries.mako.co.il/assets/client/share.png';
		const data = {
			'@context': 'https://schema.org',
			'@type': 'ItemList',
			url: `${APP_CONFIG.domains.client.hostname}`,
			name: SEO.general.seoTitle,
			description: SEO.general.seoDescription,
			itemListElement: _.map(starredShows, (show: ModifiedShowItem, index) => ({
				'@type': 'ListItem',
				position: index + 1,
				item: {
					'@type': 'TVSeries',
					image: show.imgix,
					name: show.nameHebrew || show.name,
				},
			})),
		};
		const data2 = {
			'@context': 'https://schema.org/',
			'@type': 'HowTo',
			name: 'מה רואים היום?',
			description:
				'אז מה רואים היום? tvbee כאן כדי לעזור לכם למצוא את הסדרות הכי מומלצות, הכי חדשות והכי טובות שיש היום בטלוויזיה. כל מה שצריך זה לסמן את הסדרות שאהבתם מתוך הרשימה, ולציין אילו פלטפורמות תוכן ושירותי סטרימינג יש לכם בבית. אנחנו כבר נבין אם אתם טיפוס של דרמה, קומדיה או בכלל מתח, ואיזו סדרה זמינה בהוט, יס, נטפליקס או אמזון פריים, ונדאג למצוא לכם את ההמלצה הבאה.',
			step: [
				{
					'@type': 'HowToStep',
					text:
						'בשלב הראשון יופיעו בפניכם 18 סדרות - דרמה, קומדיה, אנימציה, דוקו ומה לא. תעברו על הרשימה, תחשבו טוב, ותסמנו את 3-5 הסדרות שהכי נהניתם מהן, והכי קרובות לדבר הבא שתרצו לראות.',
					name: 'הסדרות שהכי אהבתם',
				},
				{
					'@type': 'HowToStep',
					text:
						'יס או הוט? נטפליקס או אמזון פריים? סלקום או פרטנר? כדי שנדע למצוא לכם את הסדרה המושלמת נצטרך לדעת אילו שירותים יש לכם בבית. אם אתם מעדיפים להסתדר עצמאית - זה בסדר, אפשר לדלג הלאה.',
					name: 'בחירת פלטפורמות תוכן',
				},
				{
					'@type': 'HowToStep',
					text:
						"אחרי שעברנו על כל הנתונים נציג בפניכם את ההמלצה שהכי מתאימה לטעם שלכם. האפשרות הראשונה לא נראית לכם? מזל שיש לנו 3 אפשרויות. צפיתם כבר באחת הסדרות? תלחצו על 'החלף סדרה' ותקבלו אחת אחרת. מתחרטים על הבחירות שסימנתם? הכל בסדר, תמיד אפשר לחזור לאחור. ברגע שתסיימו ניתן יהיה לשמור את התוצאות, לשתף בסטורי - ובעיקר להתחיל לצפות. אחר כך כבר נעזור לכם עם עוד המלצות.",
					name: 'ההמלצות',
				},
			],
		};

		return (
			<Helmet>
				<title>{title}</title>

				<link rel="icon" type="image/png" href={faviconImg} />
				<link rel="canonical" href={location.pathname} />

				<meta name="description" content={description} />
				<meta property="og:title" content={ogTitle} />
				<meta property="og:description" content={ogDescription} />
				<meta property="og:url" content="" />
				<meta property="og:type" content="website" />
				<meta property="og:image" content={image} />

				<script type="application/ld+json">{JSON.stringify(data)}</script>
				<script type="application/ld+json">{JSON.stringify(data2)}</script>
			</Helmet>
		);
	};

	render() {
		const {
			className,
			transitionState,
			checkedShows,
			starredShows,
			manageCheckedShow,
			setInfoPopUp,
			history,
		} = this.props;
		return (
			<div className={classNames(css.shows, css[transitionState], className)}>
				{this.renderHelmet()}
				<Header />
				<FirstScreen
					setInfoPopUp={setInfoPopUp}
					shows={starredShows}
					checkedShows={checkedShows}
					manageCheckedShow={manageCheckedShow}
					history={history}
				/>
				<MakoAnalytics pageId={MAKO_ANALYTICS_PAGE_IDS.MAIN} />
			</div>
		);
	}
}

export default withTransition(Shows);
