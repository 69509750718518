// @flow
/* eslint-disable no-plusplus */

import _ from 'lodash';

export const sortedByNewAmazingLogic = (recommendations: any) => {
	const result = [];
	const pairsByIndex = [];
	let firstItem = null;
	let allPairs = [];
	let topPairs = [];

	if (!recommendations || recommendations?.length === 0) return [];

	_.forEach(recommendations, (r, index) => {
		[firstItem] = r;
		pairsByIndex.push(getPairs(firstItem.genre));
		allPairs = [...allPairs, ...getPairs(firstItem.genre)];
	});

	topPairs = _.orderBy(
		_.reduce(
			allPairs,
			(acc, cur) => {
				const index = _.findIndex(acc, { pair: cur });
				acc.push({ pair: cur, value: index < 0 ? 1 : acc[index].value + 1 });
				return acc;
			},
			[]
		),
		['value'],
		['desc']
	).slice(0, 3);

	const indexes = [];

	_.forEach(topPairs, pair => {
		const i = _.findIndex(pairsByIndex, currPairs => currPairs.includes(pair.pair));

		if (i >= 0 && !indexes.includes(i)) {
			result.push(recommendations[i]);
			indexes.push(i);
		}
	});

	if (result.length < 3) {
		const recommendationIndexes = _.map(recommendations, (item, index: number) => index);

		_.forEach(Array.from({ length: 3 - result.length }), () => {
			const availableIndexes = recommendationIndexes
				.filter(x => !indexes.includes(x))
				.concat(indexes.filter(x => !recommendationIndexes.includes(x)));
			// console.info('availableIndexes', availableIndexes);

			result.push(recommendations[availableIndexes[0]]);
			indexes.push(availableIndexes[0]);
		});
	}

	return result;
};

const getPairs = arr => {
	if (!arr || arr?.length === 0) return [];

	const result = [];

	for (let i = 0; i < arr.length; ++i) {
		for (let j = i + 1; j < arr.length; ++j) {
			if (arr[j]) {
				result.push(arr[i].localeCompare(arr[j]) === -1 ? `${arr[i]}:${arr[j]}` : `${arr[j]}:${arr[i]}`);
			}
		}
	}

	return result;
};
