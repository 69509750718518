/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';
import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { CAN_USE_DOM } from 'common/constants';
import { SEO, MAKO_ANALYTICS_PAGE_IDS } from 'client/constants';
import { Header, MakoAnalytics } from 'client/components/common';
import faviconImg from 'assets/favicon.png';
import { analytics } from 'common/utils';
import ResultScreen from '../../common/Sreens/ResultScreen';
import shareItems from './shareItems';
import * as css from './Recommendations.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	recommendations: Array<Array<any>>,
	history: RouterHistory,
	selectedChannels: Array<string>,
	// setSlectedChannels: (Array<string>) => void,
	location: RouterLocation,
|};

type State = {
	recommendations: Array<Array<any>>,
	shareRecommendations: Array<any>,
};

class Recommendations extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	state = {
		recommendations: [],
		shareRecommendations: [],
	};

	componentDidMount() {
		const { recommendations, location } = this.props;
		const url = location.pathname.split('/')[1];
		// const { setSlectedChannels } = this.props;

		if (CAN_USE_DOM) {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
			analytics.pageview(url);
		}

		if (recommendations.length !== 0) {
			const shareRecommendations = shareItems(recommendations);
			this.setState({
				recommendations,
				shareRecommendations,
			});
		} else {
			const storageRecommendations = localStorage.getItem('recommendations');
			if (storageRecommendations) {
				const stateRecommendations = JSON.parse(storageRecommendations);
				const shareRecommendations = shareItems(stateRecommendations);
				this.setState({
					recommendations: stateRecommendations,
					shareRecommendations,
				});
			} else {
				this.setState({
					recommendations: [],
					shareRecommendations: [],
				});
			}
		}
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	setRecommendations = (index, recommendation) => {
		const { shareRecommendations } = this.state;
		const modifiedShareRecommendations = shareRecommendations.map((item, i) => {
			if (i === index) {
				return recommendation;
			}

			return item;
		});
		this.setState({
			shareRecommendations: modifiedShareRecommendations,
		});
	};

	renderHelmet = () => {
		const { location } = this.props;
		const title = SEO.general.seoTitle;
		// eslint-disable-next-line max-len
		const description = SEO.general.seoDescription;
		const ogTitle = SEO.general.shareTitle;
		const ogDescription = SEO.general.shareDescription;
		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = 'https://myseries.mako.co.il/assets/client/share.png';

		return (
			<Helmet
				title={title}
				link={[
					{ rel: 'icon', type: 'image/png', href: faviconImg },
					{ rel: 'canonical', href: location.pathname },
				]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescription },
					{ property: 'og:image', content: image },
					{ property: 'og:url,', content: '' },
					{ property: 'og:type', content: 'website' },
				]}
			/>
		);
	};

	render() {
		const { className, transitionState, history, selectedChannels } = this.props;
		const { recommendations, shareRecommendations } = this.state;
		return (
			<div className={classNames(css.recommendationsResults, css[transitionState], className)}>
				{this.renderHelmet()}
				<Header withBackBtn isHideSocialls history={history} />
				<ResultScreen
					history={history}
					recommendations={recommendations}
					setRecommendations={this.setRecommendations}
					selectedChannels={selectedChannels}
					shareRecommendations={shareRecommendations}
				/>
				<MakoAnalytics pageId={MAKO_ANALYTICS_PAGE_IDS.RESULTS} />
			</div>
		);
	}
}

export default withTransition(Recommendations);
