// @flow

export const ITEMS_PAGE_SIZE = 10;
export const CONFIRMATION_POP_UP = 'CONFIRMATION_POP_UP';
export const ADD_CATEGORY_POP_UP = 'ADD_CATEGORY_POP_UP';
export const EDIT_CATEGORY_POP_UP = 'EDIT_CATEGORY_POP_UP';

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};

export const GET_ITEMS_DEFAULT_PARAMS = {
	page: 1,
	sort: 'createdDate',
	order: 'desc',
};

export const DEMO_ITEM_DEFAULT_PARAMS = {
	name: 'Default Name',
};

export const COLLECTIONS = {
	DEMO: 'demo',
	SECURITY: 'security',
	USERS: 'users',
	BACKUP: 'backup',
	LOGS: 'logs',
	META: 'meta',
	SETTINGS: 'settings',
	LEADS: 'leads',
	CSV_TOKENS: 'csv-tokens',
};

export const PAGE_SIZE_MAP = {
	[COLLECTIONS.DEMO]: 50,
	[COLLECTIONS.LEADS]: 50,
	DEFAULT: 30,
};
