/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import classNames from 'classnames';

import images from './channelsImages';
import * as css from './SecondScreen.scss';

type Props = {
	channel: string,
};

const ChannelImage = ({ channel }: Props) => {
	const renderImage = () => {
		switch (channel) {
			case 'yes':
				return <img className={classNames(css.image, css.yes)} src={images.yesSrc} alt="yes" />;
			case 'HOT':
				return <img className={classNames(css.image, css.hot)} src={images.hotSrc} alt="HOT" />;
			case ' סלקום TV':
				return <img className={classNames(css.image, css.cellcom)} src={images.cellcomSrc} alt="סלקום tv" />;
			case 'נטפליקס':
				return <img className={classNames(css.image, css.netflix)} src={images.netflixSrc} alt="נטפליקס" />;
			case 'פיקוק':
				return <img className={classNames(css.image, css.peacock)} src={images.peacockSrc} alt="פיקוק" />;
			case 'אמזון פריים':
				return <img className={classNames(css.image, css.prime)} src={images.primeSrc} alt="אמזון פריים" />;
			case 'מקס':
				return <img className={classNames(css.image, css.hbo)} src={images.maxSrc} alt="מקס" />;
			case 'אפל TV פלוס':
				return <img className={classNames(css.image, css.apple)} src={images.appleSrc} alt="אפל TV פלוס" />;
			case 'פרטנר tv':
				return <img className={classNames(css.image, css.partner)} src={images.partnerSrc} alt="פרטנר tv" />;
			case 'הולו':
				return <img className={classNames(css.image, css.hulu)} src={images.huluSrc} alt="הולו" />;
			case 'דיסני+':
				return <img className={classNames(css.image, css.disney)} src={images.disneySrc} alt="+Disney" />;
			case 'פרמאונט+':
				return (
					<img className={classNames(css.image, css.paramoumt)} src={images.paramountSrc} alt="פרמאונט+" />
				);
			case 'דיסקברי+':
				return (
					<img
						className={classNames(css.image, css.discoveryPlus)}
						src={images.discoveryPlusSrc}
						alt="Discovery+"
					/>
				);
			case 'FreeTV':
				return <img className={classNames(css.image, css.freeTv)} src={images.freeTvSrc} alt="Free tv" />;

			default:
				return <p>{channel}</p>;
		}
	};

	return <>{renderImage()}</>;
};

export default ChannelImage;
