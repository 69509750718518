/* eslint-disable react/no-danger */
// @flow
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import * as css from './TextField.scss';

// const onlyHebrewPattern = new RegExp(/^[\u0590-\u05FF 0-9 /\W\r\n-]+$/, 'g');
const emailRegExp = /[^a-zA-Z@. 0-9]/;

// const onlyHebrewPattern2 = new RegExp(/^[\u0590-\u05FF r\n-]+$/);
// const onlyHebrewPatternAdressField = new RegExp(/^[\u0590-\u05FF 0-9 r\n-]+$/);

type Props = {
	input: {|
		name: string,
		onBlur: Function,
		onChange: Function,
		onDragStart: Function,
		onDrop: Function,
		onFocus: Function,
		value: string | number,
	|},
	meta: {|
		active?: boolean,
		asyncValidating?: boolean,
		autofilled?: boolean,
		dirty?: boolean,
		dispatch?: Function,
		error?: string,
		form?: string,
		initial?: string,
		invalid?: boolean,
		pristine?: boolean,
		submitFailed?: boolean,
		submitting?: boolean,
		touched?: boolean,
		valid?: boolean,
		visited?: boolean,
		warning?: string,
	|},
	inputRef?: { current: ?HTMLInputElement | ?HTMLTextAreaElement } | null,
	className?: string,
	placeholder?: string,
	eventListeners: {|
		onChange?: Function,
		onFocus?: Function,
		onBlur?: Function,
		onKeyDown?: Function,
		onKeyUp?: Function,
	|},
	isTextarea?: boolean,
	textareaType?: number,
	inlineType?: boolean,
	disabled?: boolean,
	clearValue?: boolean,
	maxWordsLength?: number,
	isNumberField?: boolean,
	minNumber: number,
	maxNumber: number,
	type?: string,
	pattern?: string,
	isEmailField?: boolean,
	maxLength?: number,
};

type State = {|
	isFocused: boolean,
	value?: string | number,
	valueState: number | string,
|};

export default class Input extends React.PureComponent<Props, State> {
	static defaultProps = {
		inputRef: null,
		className: '',
		placeholder: '',
		isTextarea: false,
		textareaType: 1,
		inlineType: false,
		disabled: false,
		clearValue: false,
		maxWordsLength: 1500,
		isNumberField: false,
		type: '',
		pattern: '',
		isEmailField: false,
		maxLength: 10000,
	};

	static getDerivedStateFromProps(props: Props, state: State) {
		if (props.input.value !== state.value) {
			return {
				value: props.input.value,
			};
		}

		return null;
	}

	constructor(props: Props) {
		super(props);

		this.state = {
			isFocused: false,
			value: props.input.value,
			valueState: '',
		};
	}

	onFocus = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: true });

		_.invoke(this.props, 'input.onFocus', e);
	};

	onBlur = (e: SyntheticInputEvent<HTMLInputElement>) => {
		this.setState({ isFocused: false });

		_.invoke(this.props, 'input.onBlur', e);
	};

	onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
		const { value } = e.target;
		// const { maxWordsLength, maxNumber, minNumber, type } = this.props;
		const { maxWordsLength } = this.props;
		let nextValue = value;

		if (nextValue && this.props.pattern) {
			const regExp = new RegExp(`${this.props.pattern}`, 'i');
			const isMatch = regExp.test(value);
			if (!isMatch) nextValue = this.state.valueState;
		}

		if (nextValue && maxWordsLength) {
			const isMatch =
				nextValue
					.toString()
					.trim()
					.split(/\s+/).length <
				maxWordsLength + 1;
			if (!isMatch) nextValue = this.state.valueState;
		}

		_.invoke(this.props, 'input.onChange', e);
		this.setState({ valueState: nextValue });
	};

	validation = (letter: string, event: any) => {
		const { isEmailField } = this.props;
		if (isEmailField && emailRegExp.test(letter)) {
			event.preventDefault();
		}
	};

	render() {
		const {
			className,
			placeholder,
			isTextarea,
			textareaType,
			inlineType,
			disabled,
			clearValue,
			inputRef,
			type,
			// pattern,
			...rest
		} = this.props;
		const { isFocused } = this.state;
		const { maxLength } = this.props;

		const value = clearValue ? '' : _.get(rest, 'input.value', '');
		// console.info('value', value);
		// const isErrorShown = (rest.meta.error && rest.meta.submitFailed) || (rest.meta.error && rest.meta.touched);

		return (
			<label
				className={classNames(
					css.textField,
					(isFocused || value) && css.active,
					isFocused && css.focused,
					className
				)}
			>
				{placeholder && (
					<div
						className={classNames(css.placeholder, {
							[css.hide]: rest.meta.active || rest.meta.dirty,
							[css.forTextarea]: isTextarea,
						})}
						dangerouslySetInnerHTML={{ __html: placeholder }}
					></div>
				)}
				{isTextarea && (
					<textarea
						onChange={this.onChange}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						// {...rest.input}
						value={this.state.valueState}
						ref={inputRef}
						{...rest.eventListeners}
						className={classNames(textareaType === 1 && css.small, textareaType === 2 && css.big)}
						disabled={disabled}
						// onKeyPress={event => {
						// 	if (!onlyHebrewPattern.test(event.key)) {
						// 		if (event.key === 'Enter') {
						// 			console.info();
						// 		} else {
						// 			event.preventDefault();
						// 		}
						// 	}
						// }}
					></textarea>
				)}
				{!isTextarea && (
					<input
						{...rest.input}
						value={this.state.valueState}
						ref={inputRef}
						{...rest.eventListeners}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						className={classNames(inlineType && css.inline)}
						onChange={this.onChange}
						type={type || 'text'}
						maxLength={maxLength}
					/>
				)}
			</label>
		);
	}
}
