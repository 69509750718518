/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import Lottie from 'react-lottie-player';

import yellowBgDesk from 'assets/client/images/yellow-bg-desktop.svg';
import yellowBgMob from 'assets/client/images/yellow-bg-mobile.svg';

import lottieJson from '../../JsonAnim/bee_anim_first_and_second_screen.json';
import { Responsive } from '../../Responsive';

import * as css from './SecondScreen.scss';

type Props = {
	history: RouterHistory,
};

const TopSection = ({ history }: Props) => {
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const topSectionBgUrl = isMobile ? yellowBgMob : yellowBgDesk;

	return (
		<div className={css.topSection} style={{ backgroundImage: `url("${topSectionBgUrl}")` }}>
			<div className={css.topSectionWrapper}>
				<h1 className={css.headTitle}>
					<span>מה יש לכם</span>
					<span>בבית?</span>
				</h1>
				<div className={css.text}>
					<p>
						סמנו באיזה שירותים אתם משתמשים
						<br />
						ניתן לסמן יותר מאפשרות אחת
					</p>
				</div>
				<div className={css.beeAnimation}>
					<Lottie loop animationData={lottieJson} play style={{ width: 1200, height: 250 }} />
				</div>
			</div>
		</div>
	);
};

export default TopSection;
