// @flow

/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */

import * as React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet-async';

import { type EnhancedProps, withTransition } from 'client/components/common/HOCs/with-transition';
import { CAN_USE_DOM } from 'common/constants';
import { SEO } from 'client/constants';
import { Header, Responsive } from 'client/components/common';

import faviconImg from 'assets/favicon.png';

import SharesScreen from '../../common/Sreens/SharesScreen';

import * as css from './SharesRecommendations.scss';

type Props = {|
	...EnhancedProps,
	className?: string,
	in: boolean,
	items: Array<ShowItem>,
	shows: Array<ModifiedShowItem>,
	history: RouterHistory,
	location: RouterLocation,
|};

type State = {
	ids: Array<string>,
};

class SharesRecommendations extends React.PureComponent<Props, State> {
	transitionCompleteTimeout: TimeoutID;

	static defaultProps = {
		className: '',
	};

	constructor(props: Props) {
		super(props);

		const { location } = props;
		const showsIds = decodeURIComponent(location.search.split('=')[1])?.split(',') || [];
		const updatedShowsIds = [];

		showsIds.forEach((show, index) => {
			if (index === 2) {
				const updatedShow = show.split('&')[0];
				updatedShowsIds.push(updatedShow);
			} else {
				updatedShowsIds.push(show);
			}
		});

		if (CAN_USE_DOM) {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
		}

		this.state = {
			ids: updatedShowsIds,
		};
	}

	componentDidUpdate(prevProps: Props) {
		if (this.props.in !== prevProps.in) {
			const { onTransitionComplete } = this.props;
			let duration = parseInt(this.props.in ? css.transitionIn : css.transitionOut, 10);

			if (!this.props.in) {
				duration = 0;
			}
			clearTimeout(this.transitionCompleteTimeout);
			this.transitionCompleteTimeout = setTimeout(onTransitionComplete, duration);
		}
	}

	renderHelmet = () => {
		const { location, items } = this.props;
		const showsIds = decodeURIComponent(location.search.split('=')[1]).split(',');

		const showsNames = [];
		showsIds.forEach(id => {
			const show = items.find(showItem => {
				return showItem.id === id;
			});

			if (show) {
				showsNames.push(show.nameHebrew);
			}
		});

		const namesString = showsNames.join(', ');
		const title = SEO.content.seoTitle;
		// eslint-disable-next-line max-len
		const description = SEO.content.seoDescription;
		const ogTitle = SEO.content.shareTitle;
		const ogDescription = SEO.content.shareDescription;
		const ogDescriptionModified = ogDescription.replace('<1,2,3>', namesString);

		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = 'https://myseries.mako.co.il/assets/client/share.png';

		return (
			<Helmet
				title={title}
				link={[
					{ rel: 'icon', type: 'image/png', href: faviconImg },
					{ rel: 'canonical', href: location.pathname },
				]}
				meta={[
					{
						name: 'description',
						content: description,
					},
					{ property: 'og:title', content: ogTitle },
					{ property: 'og:description', content: ogDescriptionModified },
					{ property: 'og:image', content: image },
					{ property: 'og:url,', content: '' },
					{ property: 'og:type', content: 'website' },
				]}
			/>
		);
	};

	render() {
		const { className, transitionState, history, shows } = this.props;
		const { ids } = this.state;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);

		return (
			<div className={classNames(css.sharesRecommendations, css[transitionState], className)}>
				{this.renderHelmet()}
				{!isMobile && <Header />}
				<SharesScreen history={history} shows={shows} ids={ids} />
			</div>
		);
	}
}

export default withTransition(SharesRecommendations);
