/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import classNames from 'classnames';

import * as css from './SecondScreen.scss';

type Props = {
	isDisableBtn: boolean,
	clickOnSkip: Function,
	clickOnToRecomendations: Function,
};

const BottomBar = ({ isDisableBtn, clickOnSkip, clickOnToRecomendations }: Props) => {
	return (
		<div className={classNames(css.bottomBar)}>
			<div className={css.bottomBarWrapper}>
				<div className={css.rightPart}>
					<p>בחר לפחות ספק אחד</p>
				</div>

				<div className={css.leftPart}>
					<div
						className={classNames(css.btn, isDisableBtn && css.disabled)}
						onClick={clickOnToRecomendations}
					>
						תמליצו לי
					</div>
				</div>
				<div className={css.skip} onClick={clickOnSkip}>
					אני כבר אמצא איפה לראות, תמליצו לי!
				</div>
			</div>
		</div>
	);
};

export default BottomBar;
