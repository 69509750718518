/* eslint-disable max-len */
// @flow

export const SEO = {
	general: {
		seoTitle: `חדש! מה רואים היום? - המלצות צפייה בהתאמה אישית | mako`,
		seoDescription: `שוב הגיע הערב ונתקעתם בלי סדרה? אנחנו כאן כדי לעזור!
		הכנסו לאתר, סמנו אילו סדרות אהבתם ולאילו סדרות אתם ממש לא מתחברים ואנו נדאג להמליץ לכם על ההתמכרות הבאה שלכם`,
		seoDescriptionChannels: `אתם תגידו איזה סדרות אהבתם – ואנחנו נמליץ מה רואים ביס, איזה סדרות רואים בנטפליקס, מה כולם רואים בהוט, מה רואים בדיסני פלוס ואיפה כולם רואים את הסדרה שאי אפשר להפסיק לראות?`,
		shareTitle: 'מה רואים היום: סדרות מומלצות בהתאמה אישית!',
		shareDescription: 'שוב הגיע הערב ונתקעתם בלי סדרה? תנו ל-tvbee לעזור לכם',
	},
	content: {
		seoTitle: `חדש! מה רואים היום? - המלצות צפייה בהתאמה אישית | mako`,
		seoDescription: `שוב הגיע הערב ונתקעתם בלי סדרה? אנחנו כאן כדי לעזור!
		הכנסו לאתר, סמנו אילו סדרות אהבתם ולאילו סדרות אתם ממש לא מתחברים ואנו נדאג להמליץ לכם על ההתמכרות הבאה שלכם`,
		shareTitle: `מה רואים היום: סדרות מומלצות בהתאמה אישית!`,
		shareDescription: `לי המליצו על <1,2,3>. מה הסדרות שאתם חייבים לראות? בואו ל - tvbee ותגלו`,
	},
};
