/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import Lottie from 'react-lottie-player';

import yellowBgDesk from 'assets/client/images/yellow-bg-desktop.svg';
import yellowBgMob from 'assets/client/images/yellow-bg-mobile.svg';
import beeLogo from 'assets/client/images/logo-tvbee-black.svg';

import lottieJson from '../../JsonAnim/bee_animation.json';
import { Responsive } from '../../Responsive';

import * as css from './SharesScreen.scss';

type Props = {
	history: RouterHistory,
};

const TopSection = ({ history }: Props) => {
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const topSectionBgUrl = isMobile ? yellowBgMob : yellowBgDesk;

	return (
		<div className={css.topSection} style={{ backgroundImage: `url("${topSectionBgUrl}")` }}>
			<div className={css.topSectionWrapper}>
				<div className={css.beeLogo}>
					<img src={beeLogo} alt="logo" />
				</div>
				<h1 className={css.headTitle}>
					<span>3 סדרות שאתם </span>
					<span>חייבים לראות</span>
				</h1>
				<div className={css.beeAnimation}>
					<Lottie loop animationData={lottieJson} play style={{ width: 1400, height: 300 }} />
				</div>
			</div>
		</div>
	);
};

export default TopSection;
