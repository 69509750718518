/* eslint-disable no-unused-expressions */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet-async';
import _ from 'lodash';
import { CSSTransition } from 'react-transition-group';
import { produce } from 'immer';

import { detectIE } from 'common/utils/detect-ie';
import { CAN_USE_DOM } from 'common/constants';

import { tracker, analytics } from 'common/utils';
// import { ROOT_ROUTE, RESULT_RECOMMENDATIONS_ROUTE } from 'client/constants';
import {
	ROOT_ROUTE,
	MAX_SELECTED_SHOWS,
	CHANNELS_ROUTE,
	RESULT_RECOMMENDATIONS_ROUTE,
	SHARES_ROUTE,
} from 'client/constants';
import Shows from 'client/components/pages/Shows';
import Channels from 'client/components/pages/Channels';
import Recommendations from 'client/components/pages/Recommendations';
import SharesRecommendations from 'client/components/pages/SharesRecommendations';

import faviconImg from 'assets/favicon.png';

import * as css from './App.scss';

type Props = {|
	history: RouterHistory,
	location: RouterLocation,
	isBusy?: boolean,
	items: Array<ShowItem>,
|};

type State = {
	starredShows: Array<ModifiedShowItem>,
	otherShows: Array<ModifiedShowItem>,
	checkedShows: Array<ModifiedShowItem>,
	allChannels: Array<string>,
	allRecommendations: Array<Array<any>>,
	selectedChannels: Array<string>,
	popUp: { isActive: boolean, title: string },
};

const LIMIT_TYPE_MAP = {
	min: 'צריך לבחור מינימום 3 סדרות',
	max: 'ניתן לבחור עד 10 סדרות',
};
class App extends React.PureComponent<Props, State> {
	static defaultProps = {
		isBusy: true,
	};

	constructor(props: Props) {
		super(props);

		const { items } = props;
		const starredShowsModified = [];
		const otherShowsModified = [];
		const allChannels = [];
		let checkedIDsFromLS = [];
		let selectedChannelsFromLS = [];

		if (CAN_USE_DOM) {
			checkedIDsFromLS = JSON.parse(window.localStorage.getItem('selectedIds')) || [];
			selectedChannelsFromLS = JSON.parse(window.localStorage.getItem('selectedChannels')) || [];
		}

		// get starred shows
		const starredShows = items.filter((item: ShowItem) => {
			if (item.starred) {
				return item;
			}
			return null;
		});

		// get unstarred shows
		const otherShows = items.filter((item: ShowItem) => {
			const year = new Date().getFullYear();
			const firstEpisodeYear = Number(item.firstShow.split('/')[2]);
			if (!item.starred && year - firstEpisodeYear <= 3) {
				return item;
			}
			return null;
		});

		// Modify each starred item (field genre from string to array)
		starredShows.forEach(item => {
			const genreStr = item.genre.split(',');
			const genres = [];

			genreStr.forEach(i => {
				genres.push(i.trim());
			});

			const genre = { genre: genres };
			const modItem = { ...item, ...genre };

			starredShowsModified.push(modItem);
			item.channels.forEach(chanel => {
				allChannels.push(chanel);
			});
		});

		// Modify each unstarred item (field genre from string to array)
		otherShows.forEach(item => {
			const genreStr = item.genre.split(',');
			const genres = [];
			genreStr.forEach(i => {
				genres.push(i.trim());
			});
			const genre = { genre: genres };
			const modItem = { ...item, ...genre };
			otherShowsModified.push(modItem);
			item.channels.forEach(chanel => {
				allChannels.push(chanel);
			});
		});

		const uniqueChannels = _.uniq(allChannels);

		const uniqueAllChannels = _.remove(uniqueChannels, n => {
			return n !== 'משודרת בחו"ל';
		});
		const targetStarredShows = _.shuffle(starredShowsModified);
		const targetUniqueChannels = _.shuffle(uniqueAllChannels);
		// console.info('targetUniqueChannels', targetUniqueChannels);
		this.state = {
			starredShows: targetStarredShows,
			otherShows: otherShowsModified,
			checkedShows: _.filter(targetStarredShows, show => checkedIDsFromLS.includes(show.id)),
			allChannels: targetUniqueChannels,
			allRecommendations: [],
			selectedChannels: selectedChannelsFromLS,
			popUp: { isActive: false, title: '' },
		};

		tracker.init('UA-202708501-59');
	}

	async componentDidMount() {
		const isIE = detectIE();

		// this.props.checkAuthState();

		if (!this.props.isBusy) {
			this.removeRootPreloader();
		}

		if (document && document.body) {
			document.body.setAttribute('data-browser', isIE ? 'ie' : 'not-ie');
		}
	}

	async componentDidUpdate(prevProps: Props) {
		if (!this.props.isBusy && prevProps.isBusy) {
			this.removeRootPreloader();
		}
	}

	onPopUpOKClick = () => {
		this.setState(prevState => ({ popUp: { isActive: false, title: prevState.popUp.title } }));
		if (document.body) document.body.style.overflow = 'unset';
	};

	renderHelmet = () => {
		const title = '';
		// eslint-disable-next-line max-len
		const description = ``;
		// Fix for whatsapp share on IOS
		//! Attention: do not import og images as variable, write path here:
		const image = '';

		return (
			<Helmet
				title={title}
				link={[{ rel: 'icon', type: 'image/png', href: faviconImg }]}
				meta={[
					{ name: 'description', content: description },
					{ property: 'og:title', content: title },
					{ property: 'og:description', content: description },
					{ property: 'og:image', content: image },
					{ property: 'og:url,', content: '' },
					{ property: 'og:type', content: 'website' },
				]}
			/>
		);
	};

	removeRootPreloader = () => {
		const preloadingScreen: ?HTMLElement = document.querySelector('.preloading-screen');

		if (preloadingScreen) {
			preloadingScreen.addEventListener('transitionend', (e: TransitionEvent) => {
				setTimeout(() => {
					if (document && document.body && e.target === preloadingScreen) {
						document.body.removeChild(preloadingScreen);
					}
				}, 2500);
			});
			preloadingScreen.style.opacity = '0';
			preloadingScreen.style.zIndex = '-1000';
		}
	};

	onFirstScreenAppear = () => {
		this.setState({ checkedShows: [] }, () => {
			window.localStorage.setItem('selectedIds', JSON.stringify(this.state.checkedShows));
		});
	};

	onSecondScreenAppear = () => {
		this.setState({ selectedChannels: [] }, () => {
			window.localStorage.setItem('selectedChannels', JSON.stringify(this.state.selectedChannels));
		});
	};

	manageCheckedShow: ManageCheckedShowMethod = (show, action) => {
		const { checkedShows } = this.state;

		if (checkedShows.length === MAX_SELECTED_SHOWS && action === 'add') {
			this.setInfoPopUp('max');
			return;
		}

		if (action === 'add') {
			analytics.onShowItemClick(show.name);
		}

		const nextCheckedShows = produce(checkedShows, draft => {
			let itemIndexForDeleting = -1;

			if (action === 'add') {
				draft.push(show);
			} else {
				itemIndexForDeleting = _.findIndex(draft, { id: show.id });

				if (itemIndexForDeleting >= 0) draft.splice(itemIndexForDeleting, 1);
			}
		});

		this.setState({ checkedShows: [...nextCheckedShows] }, () => {
			window.localStorage.setItem('selectedIds', JSON.stringify(_.map(this.state.checkedShows, item => item.id)));
		});
	};

	manageSelectedChannel: ManageSelectedChannelMethod = (name, action) => {
		const { selectedChannels } = this.state;

		if (action === 'add') {
			analytics.onChannelItemClick(name);
		}

		const nextSelectedChannels = produce(selectedChannels, draft => {
			let itemIndexForDeleting = -1;

			if (action === 'add') {
				draft.push(name);
			} else {
				itemIndexForDeleting = _.indexOf(draft, name);

				if (itemIndexForDeleting >= 0) draft.splice(itemIndexForDeleting, 1);
			}
		});

		this.setState({ selectedChannels: nextSelectedChannels }, () => {
			window.localStorage.setItem('selectedChannels', JSON.stringify(this.state.selectedChannels));
		});
	};

	setInfoPopUp = (limitType: 'max' | 'min') => {
		this.setState({
			popUp: { isActive: true, title: LIMIT_TYPE_MAP[limitType] || '' },
		});

		if (document.body) document.body.style.overflow = 'hidden';
	};

	setAllRecommendations = (recommendations: any) => {
		this.setState({
			allRecommendations: recommendations,
		});
	};

	setSlectedChannels = (selectedChannels: Array<string>) => {
		this.setState({
			selectedChannels,
		});
	};

	renderInfoPopUp = () => {
		const { popUp } = this.state;

		return (
			<CSSTransition
				in={popUp.isActive}
				timeout={parseInt(css.fadeInOut, 10)}
				classNames={{
					appear: css.fadeAppear,
					appearActive: css.fadeAppearActive,
					appearDone: css.fadeAppearDone,
					enter: css.fadeEnter,
					enterActive: css.fadeEnterActive,
					enterDone: css.fadeEnterDone,
					exit: css.fadeExit,
					exitActive: css.fadeExitActive,
					exitDone: css.fadeExitDone,
				}}
				enter
				exit
				unmountOnExit
			>
				<div className={css.popUp}>
					<div className={css.popUpBg} onClick={this.onPopUpOKClick} />
					<div className={css.popUpContent}>
						<p>{popUp.title || ''}</p>
						<button onClick={this.onPopUpOKClick} type="button" />
					</div>
				</div>
			</CSSTransition>
		);
	};

	render() {
		const {
			starredShows,
			otherShows,
			checkedShows,
			allChannels,
			allRecommendations,
			selectedChannels,
		} = this.state;
		const { items } = this.props;

		return (
			<div id="app" className={css.app}>
				{/* {this.renderHelmet()} */}
				<div className={css.content}>
					<Route exact path={ROOT_ROUTE}>
						{props => (
							<Shows
								{...props}
								in={!!props.match}
								onInit={this.onFirstScreenAppear}
								starredShows={starredShows}
								otherShows={otherShows}
								setInfoPopUp={this.setInfoPopUp}
								checkedShows={checkedShows}
								allChannels={allChannels}
								manageCheckedShow={this.manageCheckedShow}
								setAllRecommendations={this.setAllRecommendations}
							/>
						)}
					</Route>
					<Route exact path={CHANNELS_ROUTE}>
						{props => (
							<Channels
								{...props}
								in={!!props.match}
								onInit={this.onSecondScreenAppear}
								allChannels={allChannels}
								otherShows={otherShows}
								checkedShows={checkedShows}
								selectedChannels={selectedChannels}
								setAllRecommendations={this.setAllRecommendations}
								manageSelectedChannel={this.manageSelectedChannel}
							/>
						)}
					</Route>
					<Route exact path={RESULT_RECOMMENDATIONS_ROUTE}>
						{props => (
							<Recommendations
								{...props}
								in={!!props.match}
								recommendations={allRecommendations}
								selectedChannels={selectedChannels}
								setSlectedChannels={this.setSlectedChannels}
							/>
						)}
					</Route>
					<Route exact path={SHARES_ROUTE}>
						{props => (
							<SharesRecommendations {...props} in={!!props.match} shows={otherShows} items={items} />
						)}
					</Route>
				</div>

				{this.renderInfoPopUp()}
			</div>
		);
	}
}

const mapState = (state: ClientStore) => ({
	isBusy: false,
	items: state.items.items,
});

const mapDispatch = {};

export default withRouter(connect(mapState, mapDispatch)(App));
