import React from 'react';
import PropTypes from 'prop-types';
import * as css from './BottomLink.scss';

const BottomLink = ({ theme }) => {
	return (
		<a
			href="https://www.mako.co.il/help-5bb6bf4ccf39c110/Article-b7ff18a9de86471026.htm?partner=NewsfooterLinks"
			target="_blank"
			rel="noreferrer"
			className={`${css.link} ${css[theme]}`}
		>
			הסדרי נגישות
		</a>
	);
};

BottomLink.propTypes = {
	theme: PropTypes.oneOf(['dark', 'light']),
};

BottomLink.defaultProps = {
	theme: 'light',
};

export default BottomLink;
