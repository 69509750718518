// @flow

import * as React from 'react';
import classNames from 'classnames';

import * as css from './FirstScreen.scss';

type Props = {
	className?: string,
	max: number,
	min: number,
	selectedItemsLength: number,
	isDisableBtn: boolean,
	btnClick: Function,
};

const ProgressBar = ({ className, max, min, selectedItemsLength, isDisableBtn, btnClick }: Props) => {
	const isShow = selectedItemsLength > 0;
	return (
		<div className={classNames(css.progressBar, isShow && css.show)}>
			<div className={css.progressBarWrapper}>
				<div className={css.rightPart}>
					<div className={css.checkedItemsCounter}>{`בחרתם ${selectedItemsLength}/${max} סדרות`}</div>
					<div className={css.additionalText}>עליכם לבחור לפחות 3 סדרות</div>
				</div>

				<div className={css.leftPart}>
					<div className={classNames(css.btn, isDisableBtn && css.disabled)} onClick={btnClick}>
						בואו נמשיך
					</div>
				</div>
			</div>
		</div>
	);
};

ProgressBar.defaultProps = {
	className: '',
};

export default ProgressBar;
