// @flow

import * as React from 'react';
import classNames from 'classnames';

import { APP_CONFIG } from 'common/constants';
import { share } from 'client/utils/share';
// import { IS_DEVEL } from 'common/constants';

import { gaFour } from 'common/utils';
import { SHARE_CONSTANTS } from 'client/constants';

import { Icon } from '../Icon';

import * as css from './Socials.scss';

type Props = {
	className?: string,
	minified?: boolean,
	isHide?: boolean,
	// shareCategory: string,
	isInContent?: boolean,
	names?: string,
	ids?: string,
};

type State = {
	isOpen: boolean,
	isCopied: boolean,
};

type ReactObjRef = { current: HTMLDivElement | null };
export default class Socials extends React.Component<Props, State> {
	sociallsRef: ReactObjRef;
	static defaultProps = {
		className: '',
		minified: false,
		isHide: false,
		isInContent: false,
		names: '',
		ids: '',
	};

	constructor(props: Props) {
		super(props);

		this.state = {
			isOpen: false,
			isCopied: false,
		};

		this.sociallsRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener('click', this.documentClickListener);
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.documentClickListener);
	}

	documentClickListener = (e: MouseEvent) => {
		if (this.sociallsRef.current) {
			if (this.sociallsRef.current.contains((e.target: any))) return false;
		}

		this.setState({
			isOpen: false,
		});
	};

	toggleOpenMenu = (e: SyntheticEvent<HTMLDivElement>) => {
		this.setState(prevState => ({
			isOpen: !prevState.isOpen,
		}));
	};

	onShare = (e: SyntheticEvent<HTMLButtonElement>) => {
		const type = e.currentTarget.dataset.id;
		const { provider } = e.currentTarget.dataset;
		const { isInContent = false, names = '', ids = '' } = this.props;
		// events.onShareClick(type, this.props.shareCategory);
		// if (!IS_DEVEL) {
		share(type, isInContent, names, ids);
		// }
		if (isInContent) {
			gaFour.gtag.event('share_results', { CUSTOM_PARAMETER: provider });
		} else {
			gaFour.gtag.event('header click', { CUSTOM_PARAMETER: provider });
		}
	};

	onCopyClick = (e: SyntheticEvent<HTMLButtonElement>) => {
		this.setState({ isCopied: true });
		const { copy } = e.currentTarget.dataset;
		// const type = e.currentTarget.dataset.id;
		const { isInContent = false } = this.props;
		if (isInContent) {
			gaFour.gtag.event('share_results', { CUSTOM_PARAMETER: 'link share' });
		} else {
			gaFour.gtag.event('header click', { CUSTOM_PARAMETER: 'link share' });
		}
		if (document && document.body) {
			const el = document.createElement('textarea');
			el.readOnly = true;
			// eslint-disable-next-line max-len
			el.value = `${copy}`;
			// flow-disable-next-line
			document.body.appendChild((el: HTMLTextAreaElement));
			el.select();
			document.execCommand('copy');
			// flow-disable-next-line
			document.body.removeChild((el: HTMLTextAreaElement));

			setTimeout(() => {
				this.setState({ isCopied: false });
			}, 1500);
		}
	};

	render() {
		const { className, minified, isHide, isInContent } = this.props;
		const { isOpen, isCopied } = this.state;
		const copyLink = `${APP_CONFIG.domains.client.hostname}`;
		const copyIconType = isCopied ? 'check' : 'copy';
		const whatsAppIconType = isInContent ? 'whatsapp-black' : 'whatsapp';
		const fbAppIconType = isInContent ? 'facebook-black' : 'facebook';
		const hintLabel = 'הלינק הועתק בהצלחה!';

		return (
			<div
				className={classNames(
					css.socials,
					this.state.isOpen && css.open,
					minified && css.minified,
					isHide && css.hide,
					className
				)}
				ref={this.sociallsRef}
			>
				<div className={css.overflowWrap}>
					{!isInContent && (
						<button
							className={css.socialButton}
							type="button"
							data-id={SHARE_CONSTANTS.COPY}
							data-copy={copyLink}
							onClick={this.onCopyClick}
						>
							<Icon type={copyIconType} width={37} height={37} className={css.iconCopy} />
							{isCopied && <div className={css.hint}>{hintLabel}</div>}
						</button>
					)}
					<button
						type="button"
						className={css.socialButton}
						data-id={SHARE_CONSTANTS.WHATSAPP}
						data-provider="WhatsUp"
						onClick={this.onShare}
					>
						<Icon type={whatsAppIconType} width={37} height={37} className={css.iconWhatsapp} />
					</button>
					<button
						className={css.socialButton}
						type="button"
						data-id={SHARE_CONSTANTS.FACEBOOK}
						data-provider="FB"
						onClick={this.onShare}
					>
						<Icon type={fbAppIconType} width={37} height={37} className={css.iconFacebook} />
					</button>
					{isInContent && (
						<button
							type="button"
							className={css.socialButton}
							data-id={SHARE_CONSTANTS.EMAIL}
							onClick={this.onShare}
							data-provider="Mail"
						>
							<Icon type="mail-black" width={37} height={37} className={css.iconMail} />
						</button>
					)}
				</div>
				{minified && (
					<div className={`${css.toggleBtn} ${isOpen ? css.open : ''}`} onClick={this.toggleOpenMenu}>
						<Icon
							type="share"
							width={30}
							height={30}
							color={isOpen ? '#000' : '#ed1c22'}
							className={`${css.iconToggle} `}
						/>
					</div>
				)}
			</div>
		);
	}
}
