/* eslint-disable max-len */
// @flow

export const FILE_DEFAULT: FileURL = {
	remoteURL: '',
	file: { name: '', url: '' },
	selected: 'remoteURL',
};
export const IS_DEVEL = process.env.NODE_ENV !== 'production';

export const SHARE_CONSTANTS = {
	WHATSAPP: 'WhatsApp',
	TWITTER: 'Twitter',
	FACEBOOK: 'Facebook',
	COPY: 'Copy',
	EMAIL: 'Email',
};

export const COLLECTIONS = {
	SETTINGS: 'settings',
	LEADS: 'leads',
};

export const MAKO_ANALYTICS_PAGE_IDS = {
	MAIN: 'HP',
	PROVIDERS: 'providers',
	RESULTS: 'results',
};

export const MIN_SELECTED_SHOWS = 3;
export const MAX_SELECTED_SHOWS = 10;
