// @flow
import { combineReducers } from 'redux';
import type { Reducer, Action } from 'redux';
import { reducer as formReducer } from 'redux-form';

import user from './user';
import settings from './settings';
import items from './items';
import starredItems from './starredItems';

const reducer: Reducer<ClientStore, Action<{ type: string, payload: any }>> = combineReducers({
	user,
	settings,
	items,
	starredItems,
	form: formReducer,
});

export default reducer;
