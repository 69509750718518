import ReactGA from 'react-ga';
import { IS_DEVEL } from 'client/constants';
import debug from 'debug';

const log = debug('app:client:analytics');
let options = {};

if (IS_DEVEL) {
	options = { debug: false };
}

export const tracker = {
	init: function init(id) {
		if (typeof window === 'undefined') {
			return;
		}
		if (!id) {
			console.warn('There is no google analytics ID.');
			return;
		}
		log('init', id);
		ReactGA.initialize(id, options);
	},
	pageview: function pageview(...args) {
		log('pageview', ...args);
		ReactGA.pageview(...args);
	},
	event: function event(...args) {
		log('event', ...args);
		ReactGA.event(...args);
	},
	ga: function ga(args) {
		log('ga', ...args);
		ReactGA.ga(...args);
	},
};

export function track({ type, payload }) {
	try {
		tracker[type](payload);
	} catch (error) {
		if (IS_DEVEL) {
			console.warn('Failed to track analytics event', error);
		}
	}
}

export const analytics = {
	onHeaderSharesClick: provider => {
		track({
			type: 'event',
			payload: { category: 'header_click', action: `${provider}` },
		});
	},
	onContentSharesClick: provider => {
		track({
			type: 'event',
			payload: { category: 'share_results', action: `${provider}` },
		});
	},
	onHeaderCopyClick: () => {
		track({
			type: 'event',
			payload: { category: 'header_click', action: 'link_share' },
		});
	},
	onMakoLogoClick: () => {
		track({
			type: 'event',
			payload: { category: 'header_click', action: 'logo_mako' },
		});
	},
	onTvBeeLogoClick: () => {
		track({
			type: 'event',
			payload: { category: 'header_click', action: 'logo_tvbee' },
		});
	},
	onShowItemClick: showName => {
		track({
			type: 'event',
			payload: { category: 'program_choice', action: showName },
		});
	},
	onShowsScreenNextClick: showsNames => {
		track({
			type: 'event',
			payload: { category: 'next_click', action: '1', label: showsNames },
		});
	},
	onChannelsScreenNextClick: channelsNames => {
		track({
			type: 'event',
			payload: { category: 'next_click', action: '2', label: channelsNames },
		});
	},
	onChannelItemClick: channelName => {
		track({
			type: 'event',
			payload: { category: 'provider_choice', action: channelName },
		});
	},
	onChannelsScreenSkipClick: () => {
		track({
			type: 'event',
			payload: { category: 'next_click', action: '2', label: 'no_provider' },
		});
	},
	onReplaceClick: name => {
		track({
			type: 'event',
			payload: { category: 'replace', action: name },
		});
	},
	onRecomendationLinkClick: name => {
		track({
			type: 'event',
			payload: { category: 'review', action: name },
		});
	},
	onClickMobileShare: () => {
		track({
			type: 'event',
			payload: { category: 'story', action: 'share' },
		});
	},
	onBackClick: name => {
		track({
			type: 'event',
			payload: { category: 'back', action: name },
		});
	},
	onBackUpBtnClick: () => {
		track({
			type: 'event',
			payload: { category: 'SWIPE UP', action: 'click' },
		});
	},
	onFirstResults: names => {
		track({
			type: 'event',
			payload: { category: 'recommendation', action: `${names}` },
		});
	},
	pageview: url =>
		track({
			type: 'ga',
			payload: ['send', 'pageview', `/${url}`],
		}),
};
