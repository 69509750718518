/* eslint-disable react/no-did-update-set-state */
// @flow
/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */

import * as React from 'react';
import classNames from 'classnames';
import { gaFour } from 'common/utils';
import { ROOT_ROUTE } from 'client/constants';

import RabbiCredit from '../../RabbiCredit';
import BottomLink from '../../BottomLink';
import { Icon } from '../../Icon';
import TopSection from './TopSection';
import Recommendation from './Recommendation';
import MobilePopup from './MobilePopup';
import Socials from '../../Socials';

import * as css from './ResultScreen.scss';

type Props = {|
	className?: string,
	history: RouterHistory,
	recommendations: Array<Array<any>>,
	setRecommendations: (index: any, recommendation: any) => void,
	selectedChannels: Array<string>,
	shareRecommendations: Array<any>,
|};

type State = {
	isShowScreenPopup: boolean,
	isFirstEnter: boolean,
};

class ResultScreen extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		selectedChannels: [],
		isShowScreenPopup: false,
		isFirstEnter: false,
	};

	componentDidMount() {
		// console.info('shareRecommendations', this.props.shareRecommendations);
	}

	componentDidUpdate(prevProps: Props) {
		const { shareRecommendations } = this.props;
		const { isFirstEnter } = this.state;
		if (!isFirstEnter) {
			shareRecommendations.forEach(item => {
				gaFour.gtag.event('results', { CUSTOM_PARAMETER: item.nameHebrew });
			});

			this.setState({
				isFirstEnter: true,
			});
		}
	}

	getShareRecommendationIDsAndNames = () => {
		const ids = [];
		const names = [];

		this.props.shareRecommendations.forEach(recommendation => {
			ids.push(recommendation.id);
			names.push(recommendation.nameHebrew);
		});

		return { ids, names };
	};

	showPopup = () => {
		gaFour.gtag.event('story');

		this.setState({
			isShowScreenPopup: true,
		});
	};

	hidePopup = () => {
		this.setState({
			isShowScreenPopup: false,
		});
	};

	clickOnBack = () => {
		gaFour.gtag.event('Back to search', { CUSTOM_PARAMETER: 'results page' });
		this.props.history.push(ROOT_ROUTE);
	};

	render() {
		const {
			className,
			history,
			setRecommendations,
			selectedChannels,
			recommendations,
			shareRecommendations,
		} = this.props;

		const { isShowScreenPopup } = this.state;
		const { ids, names } = this.getShareRecommendationIDsAndNames();
		const backBtnLabel = 'בחזרה לחיפוש';

		return (
			<div className={classNames(css.resultScreen, className)}>
				<TopSection history={history} />
				<div className={css.recommendations}>
					{recommendations.map((showRecommendations, index) => {
						return (
							// eslint-disable-next-line react/no-array-index-key
							<Recommendation
								recommendations={showRecommendations}
								setRecommendations={setRecommendations}
								index={index}
								key={index}
								selectedChannels={selectedChannels}
							/>
						);
					})}
				</div>
				<div className={css.btnForMobileScreen} onClick={this.showPopup}>
					צלמו מסך לסטורי
					<Icon type="instagram" />
				</div>
				<div className={css.sociallsWrapper}>
					<h3>שתפו עם השותף המושלם לצפייה:</h3>
					<Socials isInContent ids={ids.join(',')} names={names.join(', ')} />
					<button type="button" className={css.backButton} onClick={this.clickOnBack}>
						{backBtnLabel}
					</button>
				</div>
				<RabbiCredit theme="dark" />
				<BottomLink theme="dark" />
				<div className={css.honeycombs}></div>
				{isShowScreenPopup && (
					<MobilePopup shareRecommendations={shareRecommendations} hidePopup={this.hidePopup} />
				)}
			</div>
		);
	}
}

export default ResultScreen;
