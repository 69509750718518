/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
// @flow
/* eslint-disable react/no-danger */
import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

// import { Responsive } from 'client/components/common/Responsive';
import { gaFour } from 'common/utils';
import Rate from './Rate';
import { Icon } from '../../Icon';

import * as css from './ResultScreen.scss';

type Props = {|
	className?: string,
	recommendations: Array<any>,
	setRecommendations: (index: any, recommendation: any) => void,
	index: number,
	selectedChannels: Array<string>,
|};

type State = {
	step: number,
	recommendations: Array<any>,
};

class Recommendation extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	state = {
		step: 0,
		recommendations: [],
	};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	get item() {
		const { step } = this.state;

		return this.sortedRecomendations.length > 0 ? this.sortedRecomendations[step] : {};
	}

	get channelsBySelection() {
		const { selectedChannels } = this.props;
		const result: Array<string> = [];

		this.item.channels.forEach(element => {
			const isFoundChannel = selectedChannels.find(el => {
				if (el.trim() === element.trim()) {
					return el.trim();
				}
				return null;
			});

			if (isFoundChannel) {
				result.push(element);
			}
		});

		return result;
	}

	get sortedRecomendations(): any {
		const { recommendations } = this.props;
		return recommendations;
	}

	clickOnSkip = () => {
		const { setRecommendations, index } = this.props;
		const { step } = this.state;
		const nextStep = step + 1;
		const item = this.sortedRecomendations[nextStep];
		const curItem = this.sortedRecomendations[step];
		gaFour.gtag.event('replace', { CUSTOM_PARAMETER: curItem.nameHebrew });
		setRecommendations(index, item);
		this.setState(prevState => ({
			step: prevState.step + 1,
		}));
	};

	onLinkClick = (e: SyntheticEvent<HTMLLinkElement>) => {
		const { name } = e.currentTarget.dataset;
		gaFour.gtag.event('review', { CUSTOM_PARAMETER: name });
	};

	render() {
		const { className, selectedChannels } = this.props;
		const { step } = this.state;
		let isHideSkipBtn;
		const genreString = this.item.genre.join(', ');
		const externalUrlLabel = 'לכל הפרטים';
		const channels = this.channelsBySelection.length > 0 ? this.channelsBySelection : this.item.channels;
		let neededChannels = [];

		if (channels.length > 3) {
			// Random channels if channels length more than 3
			neededChannels = _.sampleSize(channels, 3);
		} else {
			neededChannels = channels;
		}
		// if (channels.length > 3) {
		// 	neededChannels = channels.slice(0, 3);
		// }

		if (this.sortedRecomendations.length >= 4 && step === 3) {
			isHideSkipBtn = true;
		}

		if (this.sortedRecomendations.length < 4 && step === this.sortedRecomendations.length - 1) {
			isHideSkipBtn = true;
		}
		return (
			<div className={classNames(css.recommendation, className)}>
				{this.item && (
					<div className={classNames(css.recommendationItem)}>
						<div
							className={classNames(css.recommendationItemImage)}
							style={{ backgroundImage: `url(${this.item.imgix})` }}
						>
							<img src={this.item.imgix} alt={this.item.nameHebrew} title={this.item.nameHebrew} />
						</div>
						<div className={classNames(css.recommendationItemInfo)}>
							<div className={css.name}>{this.item.nameHebrew}</div>
							<div className={css.genre}>{genreString}</div>
							{_.size(channels) !== 0 && (
								<div className={css.channels}>
									{neededChannels.map((ch, i) => (
										<span className={css.channelItem} key={ch}>
											{ch}
										</span>
									))}
									{channels.length > 3 && <span className={css.dots}>ועוד...</span>}
								</div>
							)}
							{selectedChannels.length > 0 && this.channelsBySelection.length === 0 && (
								<div className={css.emptyChannels}>
									<Icon className={css.error} type="error" />
									לא נמצאת בספקים שבחרת
								</div>
							)}
							<a
								href={this.item.link}
								data-name={this.item.nameHebrew}
								onClick={this.onLinkClick}
								target="_blank"
								rel="noreferrer"
								className={css.toArticleMobile}
							>
								{`${externalUrlLabel} >`}
							</a>
							{!isHideSkipBtn && (
								<div className={classNames(css.btnSkip)} onClick={this.clickOnSkip}>
									<Icon type="refresh" />
									החליפו לי המלצה
								</div>
							)}
						</div>
						<Rate item={this.item} />
						{this.item.link && (
							<div className={css.toArticle}>
								<a
									href={this.item.link}
									data-name={this.item.nameHebrew}
									onClick={this.onLinkClick}
									target="_blank"
									rel="noreferrer"
								>
									<div className={css.btn}>
										<Icon type="arrow-left" color="#f7b402" />
									</div>
									<div className={css.text}>{externalUrlLabel}</div>
								</a>
							</div>
						)}
					</div>
				)}
			</div>
		);
	}
}

export default Recommendation;
