// @flow

/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */

import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import { gaFour } from 'common/utils';
import { ROOT_ROUTE } from 'client/constants';

import TopSection from './TopSection';
import Rate from './Rate';

import * as css from './SharesScreen.scss';

type Props = {|
	className?: string,
	history: RouterHistory,
	shows: Array<ModifiedShowItem>,
	ids: Array<string>,
|};

type State = {
	shareShows: Array<ModifiedShowItem>,
};

class SharesScreen extends React.PureComponent<Props, State> {
	static defaultProps = {
		className: '',
	};

	constructor(props: Props) {
		super(props);

		const { shows, ids } = props;
		const showsArr = [];

		ids.forEach(id => {
			const show = _.find(shows, { id });

			if (show) showsArr.push(show);
		});

		this.state = {
			shareShows: showsArr,
		};
	}

	toMainClick = () => {
		gaFour.gtag.event('Back to search', { CUSTOM_PARAMETER: 'results page' });
		this.props.history.push(ROOT_ROUTE);
	};

	render() {
		const { className, history } = this.props;
		const { shareShows } = this.state;

		return (
			<div className={classNames(css.sharesScreen, className)}>
				<TopSection history={history} />
				<div className={css.recommendations}>
					{shareShows.map(show => (
						<div className={classNames(css.recommendation, className)} key={show.id}>
							<div className={classNames(css.recommendationItem)}>
								<div
									className={classNames(css.recommendationItemImage)}
									style={{ backgroundImage: `url(${show.imgix})` }}
								>
									<img src={show.imgix} alt={show.nameHebrew} title={show.nameHebrew} />
								</div>
								<div className={classNames(css.recommendationItemInfo)}>
									<div className={css.name}>{show.nameHebrew}</div>
									<div className={css.genre}>{show.genre.join(', ')}</div>
									<div className={css.channels}>
										{_.map(show.channels, ch => (
											<span className={css.channelItem}>{ch}</span>
										))}
									</div>
								</div>
								<Rate item={show} />
							</div>
						</div>
					))}
				</div>

				<div className={css.btnToMain} onClick={this.toMainClick}>
					גם אתם רוצים שנמליץ לכם על סדרות? >>
				</div>

				<div className={css.honeycombs}></div>
			</div>
		);
	}
}

export default SharesScreen;
