/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unescaped-entities */
// @flow

import * as React from 'react';
import classNames from 'classnames';

import _ from 'lodash';
import * as css from './ResultScreen.scss';

type Props = {|
	item: any,
|};

type State = {
	dash: number,
};

class Rate extends React.PureComponent<Props, State> {
	static defaultProps = {};

	state = {
		dash: 0,
	};

	componentDidMount() {
		const { item } = this.props;
		setTimeout(() => {
			this.setState({
				dash: 314 + (100 - Number(item.editorsRate)) * 3.14,
			});
		}, 100);
	}

	componentDidUpdate(prevProps: Props) {
		if (!_.isEqual(prevProps.item, this.props.item)) {
			this.setState({
				dash: 314 + (100 - Number(this.props.item.editorsRate)) * 3.14,
			});
		}
	}

	render() {
		const { item } = this.props;
		const { dash } = this.state;
		return (
			<div className={classNames(css.rate)}>
				<div className={css.rateInner}>
					<svg xmlns="http://www.w3.org/2000/svg" className={css.dashWrap} viewBox="0 0 106.96 106.96">
						<path className={css.pathDash} d="M53.48,3.48a50,50,0,1,1-50,50A50,50,0,0,1,53.48,3.48Z"></path>
					</svg>
					<div className={css.fakeChart}>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 108 108">
							<circle
								cx="54"
								cy="54"
								r="50"
								style={{
									strokeDashoffset: `${dash}`,
								}}
								className={css.circleInner}
							></circle>
						</svg>
					</div>
					<div className={css.ratePercents}>{Number(item.editorsRate)}</div>
				</div>
				<div className={css.rateText}>ציון המבקרים</div>
			</div>
		);
	}
}

export default Rate;
