// @flow

/* eslint-disable prefer-destructuring */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable react/no-danger */

import * as React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { CHANNELS_ROUTE, MAX_SELECTED_SHOWS, MIN_SELECTED_SHOWS } from 'client/constants';
import { gaFour } from 'common/utils';

import RabbiCredit from '../../RabbiCredit';
import BottomLink from '../../BottomLink';
import { Responsive } from '../../Responsive';
import ProgressBar from './ProgressBar';
import Texts from './Texts';
import TopSection from './TopSection';
import Show from './Show';

import * as css from './FirstScreen.scss';

type Props = {|
	className?: string,
	shows: Array<ModifiedShowItem>,
	checkedShows: Array<ModifiedShowItem>,
	manageCheckedShow: ManageCheckedShowMethod,
	setInfoPopUp: (limitType: 'max' | 'min') => void,
	history: RouterHistory,
|};
class FirstScreen extends React.PureComponent<Props> {
	static defaultProps = {
		className: '',
	};

	componentDidMount() {}

	componentDidUpdate(prevProps: Props) {}

	get isDisableBtn() {
		return this.props.checkedShows.length < MIN_SELECTED_SHOWS;
	}

	clickOnItemHandler = (e: SyntheticEvent<HTMLDivElement>) => {
		const { shows, checkedShows, manageCheckedShow } = this.props;
		const { id } = e.currentTarget.dataset;
		const selectedShowInfo = _.find(shows, { id });
		const isShowAlreadySelected = _.findIndex(checkedShows, { id }) >= 0;

		if (selectedShowInfo) manageCheckedShow(selectedShowInfo, isShowAlreadySelected ? 'remove' : 'add');
	};

	renderItem = (item: ModifiedShowItem) => {
		const { checkedShows } = this.props;
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const selectedItemIndex = _.findIndex(checkedShows, { id: item.id });

		const originalImgxUrlWithoutParams = item.imgix.split('?')[0];
		const deskImgxUrl = `${originalImgxUrlWithoutParams}?w=400&h=246&fit=crop&crop=entropy&auto=format,enhance&q=60&fit=facearea&facepad=10`;
		const imageImgix = isMobile ? item.imgix : deskImgxUrl;

		// if (originalUrl.includes('?')) {
		// 	urlWithoutParameters = originalUrl.split('?')[0];
		// 	splitedUrl = urlWithoutParameters.split('/');
		// 	imageName = splitedUrl[splitedUrl.length - 1];
		// } else {
		// 	urlWithoutParameters = originalUrl;
		// 	splitedUrl = urlWithoutParameters.split('/');
		// 	imageName = splitedUrl[splitedUrl.length - 1];
		// }

		return (
			<Show
				item={item}
				key={item.id}
				selectedItem={selectedItemIndex >= 0}
				imageImgix={imageImgix}
				onClick={this.clickOnItemHandler}
			/>
		);
	};

	clickBtnNextListener = () => {
		const { history, checkedShows, setInfoPopUp } = this.props;

		if (this.isDisableBtn) {
			setInfoPopUp('min');
			return null;
		}

		checkedShows.forEach(item => {
			gaFour.gtag.event('program_choice', { CUSTOM_PARAMETER: item.nameHebrew });
		});

		history.push(CHANNELS_ROUTE);
	};

	render() {
		const { className, checkedShows, shows } = this.props;
		// console.info('checkedShows', checkedShows);
		return (
			<div className={classNames(css.firstScreen, className)}>
				<TopSection />
				<div className={css.itemsList}>{shows.map(this.renderItem)}</div>
				{checkedShows.length === 0 && <Texts />}
				<RabbiCredit />
				<BottomLink />
				<div className={css.honeycombs}></div>
				<ProgressBar
					selectedItemsLength={checkedShows.length}
					max={MAX_SELECTED_SHOWS}
					min={MIN_SELECTED_SHOWS}
					isDisableBtn={this.isDisableBtn}
					btnClick={this.clickBtnNextListener}
				/>
			</div>
		);
	}
}

export default FirstScreen;
