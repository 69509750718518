// @flow

import * as React from 'react';
import classNames from 'classnames';

import { Icon } from '../../Icon';
import * as css from './FirstScreen.scss';

type Props = {
	className?: string,
	selectedItem: boolean,
	item: ModifiedShowItem,
	imageImgix: string,
	// eslint-disable-next-line react/no-unused-prop-types
	onClick: Function,
};

const Show = ({ className, selectedItem, item, imageImgix, onClick }: Props) => {
	return (
		<div
			className={classNames(css.item, selectedItem && css.selected)}
			key={item.id}
			data-id={item.id}
			onClick={onClick}
		>
			<div className={css.itemImage}>
				<img src={imageImgix} alt={item.nameHebrew} title={item.nameHebrew} />
			</div>
			<div className={css.itemInfo}>
				<div className={css.name}>{item.name}</div>
				<div className={css.credit}>{`צילום: ${item.previewCredits}`}</div>
			</div>

			<div className={css.itemLike}>
				<span>אהבתי</span>
				<Icon type="like" />
			</div>
			<div className={css.itemMobileCredit}>
				<p>{`צילום: ${item.previewCredits}`}</p>
			</div>
		</div>
	);
};

Show.defaultProps = {
	className: '',
};

export default Show;
