// @flow

/* eslint-disable react/no-unescaped-entities */

import * as React from 'react';

import yellowBgDesk from 'assets/client/images/yellow-bg-desktop.svg';
import yellowBgMob from 'assets/client/images/yellow-bg-mobile.svg';
import beeImg from 'assets/client/images/bee_results.svg';

import { Responsive } from '../../Responsive';

import * as css from './ResultScreen.scss';

type Props = {
	history: RouterHistory,
};

const TopSection = ({ history }: Props) => {
	const isMobile = Responsive.isMatching(Responsive.MOBILE);
	const topSectionBgUrl = isMobile ? yellowBgMob : yellowBgDesk;

	return (
		<div className={css.topSection} style={{ backgroundImage: `url("${topSectionBgUrl}")` }}>
			<div className={css.topSectionWrapper}>
				<h1 className={css.headTitle}>
					<span>3 סדרות שאתם </span>
					<span>חייבים לראות</span>
				</h1>
				<div className={css.beeAnimation}>
					<img src={beeImg} alt="bee" />
				</div>
			</div>
		</div>
	);
};

export default TopSection;
