// @flow

/* eslint-disable react/no-unescaped-entities */

import * as React from 'react';
import { gsap } from 'gsap';

import { analytics } from 'common/utils';
import { CAN_USE_DOM } from 'common/constants';

import * as css from './FirstScreen.scss';

const Texts = () => {
	const goToTop = () => {
		analytics.onBackUpBtnClick();
		const scrolledEl = document.getElementById('top-section');
		const tweener = { value: window.pageYOffset };
		if (scrolledEl) {
			gsap.to(tweener, {
				duration: 0.5,
				value: scrolledEl.offsetTop,
				onUpdate: () => {
					window.scroll({ top: tweener.value, behavior: 'auto' });
				},
			});
		}
	};
	return (
		<div className={css.texts} style={CAN_USE_DOM ? { paddingTop: window.innerHeight * 0.8 } : {}}>
			<div className={css.title}>מה רואים היום?</div>
			<h3>החיפוש אחר סדרה הפך לסיוט</h3>
			<p>
				מיציתם את "האנטומיה של גריי"? סיימתם עוד צפייה ב"חברים"? מרגישים שאין מה לראות? השנים האחרונות - שהכניסו
				לחיינו שירותי סטרימינג כמו נטפליקס, דיסני+ ואמזון פריים - הפכו את המרדף אחרי הסדרה הגדולה הבאה לבלתי
				אפשרי, וקשה למצוא המלצות צפייה טובות. מישהו אחד מדבר על סדרת נוער ממכרת, מישהי אחרת ממליצה בכלל על מותחן
				של HBO, והאנשים שמחפשים קומדיה או דרמה טובה לצפות בה נשארים חסרי אונים.
			</p>
			<h3>מה יש לראות היום</h3>
			<p>
				סדרות פשע או רומנטיקה, בריטיות או אמריקאיות, כאלה שזמינות ביס והוט או באפל TV פלוס: הטלוויזיה שלנו מציעה
				אינספור אפשרויות, וקל מאוד ללכת לאיבוד. כל מה שרצינו זה לדעת מה לראות היום, בין אם מדובר ב"יורשים" הבאה
				או בקומדיה מצחיקה שמזכירה את "המפץ הגדול". למרבה המזל, עכשיו יש פתרון.
			</p>
			<h3>תנו לנו לעזור לכם</h3>
			<p>
				אז מה רואים היום? tvbee כאן כדי לעזור לכם למצוא את הסדרות הכי מומלצות, הכי חדשות והכי טובות שיש היום
				בטלוויזיה. כל מה שצריך זה לסמן את הסדרות שאהבתם מתוך הרשימה, ולציין אילו פלטפורמות תוכן ושירותי סטרימינג
				יש לכם בבית. אנחנו כבר נבין אם אתם טיפוס של דרמה, קומדיה או בכלל מתח, ואיזו סדרה זמינה בהוט, יס, נטפליקס
				או אמזון פריים, ונדאג למצוא לכם את ההמלצה הבאה.
			</p>
			<h3>איך עושים את זה?</h3>
			<div className={css.list}>
				<div className={css.item}>
					<span>
						<b>1. </b>
						הסדרות שהכי אהבתם
					</span>
					<p>
						בשלב הראשון יופיעו בפניכם 36 סדרות - דרמה, קומדיה, אנימציה, דוקו ומה לא. תעברו על הרשימה, תחשבו
						טוב, ותסמנו את 3-10 הסדרות שהכי נהניתם מהן, והכי קרובות לדבר הבא שתרצו לראות.
					</p>
				</div>
				<div className={css.item}>
					<span>
						<b>2. </b>
						בחירת פלטפורמות תוכן
					</span>
					<p>
						יס או הוט? נטפליקס או אמזון פריים? סלקום או פרטנר? כדי שנדע למצוא לכם את הסדרה המושלמת נצטרך
						לדעת אילו שירותים יש לכם בבית. אם אתם מעדיפים להסתדר עצמאית - זה בסדר, אפשר לדלג הלאה.
					</p>
				</div>
				<div className={css.item}>
					<span>
						<b>3. </b>
						המלצות צפייה
					</span>
					<p>
						אחרי שעברנו על כל הנתונים נציג בפניכם את ההמלצה שהכי מתאימה לטעם שלכם, כזו שתעזור לכם להחליט מה
						לראות היום. האפשרות הראשונה לא נראית לכם? מזל שיש לנו 3 המלצות. צפיתם כבר באחת הסדרות? תלחצו על
						"החלף סדרה" ותקבלו אחת אחרת. מתחרטים על הבחירות שסימנתם? הכל בסדר, תמיד אפשר לחזור לאחור. ברגע
						שתסיימו ניתן יהיה לשמור את התוצאות, לשתף בסטורי - ובעיקר להתחיל לצפות. אחר כך כבר נעזור לכם עם
						עוד סדרות מומלצות.
					</p>
				</div>
			</div>
			<div className={css.btnToTop} onClick={goToTop}>
				לראש העמוד
			</div>
		</div>
	);
};

export default Texts;
