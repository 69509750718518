// @flow

/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/forbid-prop-types */
import * as React from 'react';
import querystring from 'querystring';
import debug from 'debug';

import { CAN_USE_DOM } from 'common/constants';
import { IS_DEVEL, MAKO_ANALYTICS_PAGE_IDS } from 'client/constants';
import { Responsive } from 'client/components/common/Responsive';

import * as css from './MakoAnalytics.scss';

const log = debug('app:MakoAnalytics');

type Props = {
	pageId: string,
};

export class MakoAnalytics extends React.PureComponent<Props> {
	renderAnalyticsIframe() {
		const srcBase = 'https://mobileapp.mako.co.il/metricsCall.html';
		const { pageId } = this.props;
		const channelId = '664fc865ef6ac510VgnVCM2000002a0c10acRCRD';
		const mako_ExternalProjectID = 'EX_myseries';
		const isMobile = Responsive.isMatching(Responsive.MOBILE);
		const platform = isMobile ? 'mobile' : 'web';
		const vcmId = pageId;
		const isMainPage = vcmId === MAKO_ANALYTICS_PAGE_IDS.MAIN;
		const contentType = isMainPage ? vcmId : 'content';
		const ref = (CAN_USE_DOM && document.referrer) || '';

		const params = {
			vcmId,
			contentType,
			channelId,
			mako_ExternalProjectID,
			platform,
			ref,
		};

		const src = `${srcBase}?${querystring.stringify(params)}`;
		log('render:src', src);
		console.info('src', src);
		return (
			// putting styles inside an <object /> or an <embed />
			// breaks them in chrome no matter how you insert them
			<object className={css.analytics} type="text/html" data={src} width="0" height="0" />
		);
	}

	render() {
		if (IS_DEVEL) {
			return null;
		}

		return this.renderAnalyticsIframe();
	}
}
