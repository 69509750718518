// @flow
export const gaFour = {
	gtag: {
		event: (action: string, params?: any) => {
			// @ts-expect-error gtag is added in html.head:
			const { gtag } = window;

			if (gtag) {
				console.info('gtag:', action, params);
				gtag('event', action, params);
			} else {
				console.info('%cgtag is undefined', 'color: red');
			}
		},
	},
};
