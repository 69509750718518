import appleSrc from 'assets/client/images/channels/apple.svg';
import cellcomSrc from 'assets/client/images/channels/cellcom.png';
import disneySrc from 'assets/client/images/channels/disney.svg';
import hboMaxSrc from 'assets/client/images/channels/hbo_max.svg';
import hotSrc from 'assets/client/images/channels/hot.svg';
import huluSrc from 'assets/client/images/channels/hulu.svg';
import netflixSrc from 'assets/client/images/channels/netflix.svg';
import paramountSrc from 'assets/client/images/channels/paramount.svg';
import partnerSrc from 'assets/client/images/channels/partner.svg';
import peacockSrc from 'assets/client/images/channels/peacock.svg';
import primeSrc from 'assets/client/images/channels/prime.svg';
import yesSrc from 'assets/client/images/channels/yes.svg';
import discoveryPlusSrc from 'assets/client/images/channels/discovery_plus.png';
import maxSrc from 'assets/client/images/channels/max_logo.svg';
import freeTvSrc from 'assets/client/images/channels/freetv.svg';

const images = {
	appleSrc,
	cellcomSrc,
	disneySrc,
	hboMaxSrc,
	hotSrc,
	huluSrc,
	netflixSrc,
	paramountSrc,
	partnerSrc,
	peacockSrc,
	primeSrc,
	yesSrc,
	discoveryPlusSrc,
	maxSrc,
	freeTvSrc,
};

export default images;
